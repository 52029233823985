// src/components/CommentItem.js
import React, { useState, useRef, useEffect, useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from './ConfirmationModal';
import styles from './CommentItem.module.css';
import { usePost } from '../hooks/usePost';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Send } from 'react-feather';

const CommentItem = ({ 
  comment, 
  commenters, 
  postId, 
  commentIndex, 
  onReplyAdded, 
  onDeleteComment, 
  initialShowReplies = false, 
  replyToHighlight = null, 
}) => {
  const { t } = useTranslation();
  const [showReplies, setShowReplies] = useState(initialShowReplies);
  const [replyContent, setReplyContent] = useState('');
  const [replying, setReplying] = useState(false);
  const [replyToUserId, setReplyToUserId] = useState(null);
  const [replyCommenters, setReplyCommenters] = useState({});
  const replyInputRef = useRef(null);
  const { replyToComment, deleteComment, deleteReply } = usePost();

  const [isDeleteCommentModalOpen, setIsDeleteCommentModalOpen] = useState(false);
  const [isDeleteReplyModalOpen, setIsDeleteReplyModalOpen] = useState(false);
  const [replyToDeleteIndex, setReplyToDeleteIndex] = useState(null);

  const [repliesRendered, setRepliesRendered] = useState(false);
  const replyRefs = useRef([]);
  const commentRef = useRef(null);

  const fetchReplyCommentersUsernames = useCallback(async () => {
    const replyCommenterIds = [...new Set((comment.replies || []).map(reply => reply.userId))];
    const fetchedReplyCommenters = {};
  
    for (const id of replyCommenterIds) {
      const userDoc = await getDoc(doc(db, 'users', id));
      if (userDoc.exists()) {
        fetchedReplyCommenters[id] = userDoc.data().username || 'Anonymous';
      } else {
        fetchedReplyCommenters[id] = 'Anonymous';
      }
    }
  
    setReplyCommenters(fetchedReplyCommenters);
  }, [comment.replies]);

  useEffect(() => {
    if (showReplies) {
      fetchReplyCommentersUsernames();
    }
  }, [showReplies, fetchReplyCommentersUsernames]);

  useEffect(() => {
    if (replying && replyInputRef.current) {
      replyInputRef.current.focus();
    }
  }, [replying]);

  // Set showReplies to true when replyToHighlight is provided
  useEffect(() => {
    if (replyToHighlight !== null && !showReplies) {
      setShowReplies(true);
    }
  }, [replyToHighlight, showReplies]);

  // Update repliesRendered when replies are assigned refs
  useEffect(() => {
    if (showReplies && comment.replies && comment.replies.length > 0) {
      setRepliesRendered(false);
      const timer = setTimeout(() => {
        setRepliesRendered(true);
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [showReplies, comment.replies]);

  // Scroll to and highlight the reply or comment
  useLayoutEffect(() => {
    const scrollAndHighlight = () => {
      const replyIndex = Number(replyToHighlight);
      const replyElement = replyRefs.current[replyIndex];
      if (replyElement) {
        replyElement.scrollIntoView({ behavior: 'smooth' });
        replyElement.classList.add(styles.highlight);
        setTimeout(() => {
          replyElement.classList.remove(styles.highlight);
        }, 2000);
      }
    };

    if (showReplies && replyToHighlight !== null && repliesRendered) {
      scrollAndHighlight();
    } else if (replyToHighlight === null && initialShowReplies && commentRef.current) {
      commentRef.current.scrollIntoView({ behavior: 'smooth' });
      commentRef.current.classList.add(styles.highlight);
      setTimeout(() => {
        commentRef.current.classList.remove(styles.highlight);
      }, 2000);
    }
  }, [showReplies, replyToHighlight, repliesRendered, initialShowReplies]);

  const handleReplyChange = useCallback((e) => {
    setReplyContent(e.target.value);
    autoResizeTextarea(e);
  }, []);

  const handleReplySubmit = async (e) => {
    e.preventDefault();
    if (replyContent.trim()) {
      try {
        await replyToComment(postId, commentIndex, replyContent, replyToUserId);
        setReplyContent('');
        setReplying(false);
        setReplyToUserId(null);
        onReplyAdded();
        if (replyInputRef.current) {
          replyInputRef.current.style.height = 'auto';
        }
      } catch (error) {
        console.error('Error replying to comment:', error);
      }
    }
  };

  const handleReplyClick = () => {
    setReplying(true);
    const mentionText = `@${commenters[comment.userId] || 'Anonymous'} `;
    setReplyContent(mentionText);
    setReplyToUserId(comment.userId);
    
    // Use setTimeout to ensure the textarea is rendered before we try to focus it
    setTimeout(() => {
      if (replyInputRef.current) {
        replyInputRef.current.focus();
        replyInputRef.current.setSelectionRange(mentionText.length, mentionText.length);
      }
    }, 0);
  };

  const handleReplyToReplyClick = (userId, username) => {
    setReplying(true);
    const mentionText = `@${username} `;
    setReplyContent(mentionText);
    setReplyToUserId(userId);
    
    setTimeout(() => {
      if (replyInputRef.current) {
        replyInputRef.current.focus();
        replyInputRef.current.setSelectionRange(mentionText.length, mentionText.length);
      }
    }, 0);
  };

  const autoResizeTextarea = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + 'px';
  };

  const toggleReplies = () => {
    setShowReplies(prev => !prev);
  };

  const currentUser = auth.currentUser;

  const canDeleteComment = currentUser && currentUser.uid === comment.userId;

  // Comment Deletion Handlers
  const handleDeleteCommentClick = () => {
    setIsDeleteCommentModalOpen(true);
  };

  const handleDeleteCommentConfirm = async () => {
    try {
      await deleteComment(postId, commentIndex);
      setIsDeleteCommentModalOpen(false);
      if (onDeleteComment) {
        onDeleteComment(commentIndex);
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const handleDeleteCommentCancel = () => {
    setIsDeleteCommentModalOpen(false);
  };

  // Reply Deletion Handlers
  const handleDeleteReplyClick = (replyIndex, replyUserId) => {
    if (currentUser.uid !== replyUserId) {
      alert('You do not have permission to delete this reply.');
      return;
    }
    setReplyToDeleteIndex(replyIndex);
    setIsDeleteReplyModalOpen(true);
  };

  const handleDeleteReplyConfirm = async () => {
    try {
      await deleteReply(postId, commentIndex, replyToDeleteIndex);
      setIsDeleteReplyModalOpen(false);
      setReplyToDeleteIndex(null);
      onReplyAdded();
    } catch (error) {
      console.error('Error deleting reply:', error);
    }
  };

  const handleDeleteReplyCancel = () => {
    setIsDeleteReplyModalOpen(false);
    setReplyToDeleteIndex(null);
  }; 

  const formatContent = (content) => {
    const mentionRegex = /@([a-z0-9_]{3,30})\b/g;
    return content.split('\n').map((line, lineIndex) => (
      <React.Fragment key={lineIndex}>
        {line.split(mentionRegex).map((part, partIndex) => 
          partIndex % 2 === 0 ? (
            <span key={partIndex}>{part}</span>
          ) : (
            <span key={partIndex} className={styles.mention}>@{part}</span>
          )
        )}
        {lineIndex < content.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <div className={styles.commentItem} id={`comment-${commentIndex}`} ref={commentRef}>
      {/* Comment Content */}
      <div className={styles.commentContent}>
        <strong>{commenters[comment.userId] || t('common.anonymous')}: </strong>
        <span>{formatContent(comment.content)}</span>
      </div>
      <div className={styles.commentMeta}>
        <span className={styles.commentTimestamp}>{new Date(comment.createdAt).toLocaleString()}</span>
        <div className={styles.commentActions}>
          <button onClick={handleReplyClick} className={styles.replyButton}>{t('comments.reply')}</button>
          {canDeleteComment && (
            <button onClick={handleDeleteCommentClick} className={styles.deleteButton}>{t('comments.delete')}</button>
          )}
        </div>
      </div>
      {/* Replies */}
      {comment.replies && comment.replies.length > 0 && (
        <button onClick={toggleReplies} className={styles.toggleRepliesButton}>
          {showReplies 
            ? t('comments.hide_replies') 
            : t(`comments.view_replies_${comment.replies.length === 1 ? 'one' : 'other'}`, { count: comment.replies.length })}
        </button>
      )}
      {showReplies && (
        <div className={styles.replies}>
          {comment.replies.map((reply, idx) => (
            <div
              key={idx}
              className={styles.reply}
              id={`reply-${commentIndex}-${idx}`}
              ref={(el) => {
                replyRefs.current[idx] = el;
                if (idx === comment.replies.length - 1) {
                  setRepliesRendered(true);
                }
              }}
            >
              <div className={styles.replyContent}>
                <strong>{replyCommenters[reply.userId] || t('common.anonymous')}: </strong>
                <span>{formatContent(reply.content)}</span>
              </div>
              <div className={styles.replyMeta}>
                <span className={styles.replyTimestamp}>{new Date(reply.createdAt).toLocaleString()}</span>
                <div className={styles.commentActions}>
                  <button onClick={() => handleReplyToReplyClick(reply.userId, replyCommenters[reply.userId])} className={styles.replyButton}>{t('comments.reply')}</button>
                  {currentUser && currentUser.uid === reply.userId && (
                    <button onClick={() => handleDeleteReplyClick(idx, reply.userId)} className={styles.deleteButton}>{t('comments.delete')}</button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* Reply Form */}
      {replying && (
        <form onSubmit={handleReplySubmit} className={styles.replyForm}>
          <div className={styles.inputWrapper}>
            <textarea
              ref={replyInputRef}
              value={replyContent}
              onChange={handleReplyChange}
              onInput={autoResizeTextarea}
              placeholder={t('comments.add_reply')}
              aria-label={t('comments.add_reply')}
              rows="1"
              className={styles.replyInput}
            />
            {replyContent.trim() && (
              <button type="submit" className={styles.sendButton} aria-label={t('comments.send_reply')}>
                <Send size={18} />
              </button>
            )}
          </div>
        </form>
      )}
      {/* Confirmation Modals */}
      <ConfirmationModal
        isOpen={isDeleteCommentModalOpen}
        onClose={handleDeleteCommentCancel}
        onConfirm={handleDeleteCommentConfirm}
        message={t('comments.confirm_delete_comment')}
      />
      <ConfirmationModal
        isOpen={isDeleteReplyModalOpen}
        onClose={handleDeleteReplyCancel}
        onConfirm={handleDeleteReplyConfirm}
        message={t('comments.confirm_delete_reply')}
      />
    </div>
  );
};

export default CommentItem;
