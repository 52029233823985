// src/components/GroupDraftItem.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { doc, getDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../firebase';
import Button from './Button';
import {
  ChevronDown,
  ChevronUp,
  Edit,
  Save,
  X
} from 'lucide-react';
import './GroupDraftItem.css';

const GroupDraftItem = ({ draftId, groupId }) => {
  const { t } = useTranslation();
  const [draft, setDraft] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedDraft, setEditedDraft] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch draft data on mount and when draftId changes
  useEffect(() => {
    const fetchDraft = async () => {
      try {
        const draftRef = doc(db, 'drafts', draftId);
        const draftSnap = await getDoc(draftRef);
        
        if (draftSnap.exists()) {
          const draftData = draftSnap.data();
          setDraft(draftData);
          setEditedDraft(draftData);
        } else {
          setError(t('drafts.not_found'));
        }
      } catch (error) {
        console.error('Error fetching draft:', error);
        setError(t('drafts.fetch_error'));
      }
    };

    if (draftId) {
      fetchDraft();
    }
  }, [draftId, t]);

  // Toggle expanded state
  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Handle edit mode toggle
  const handleEdit = () => {
    setEditMode(true);
    setIsExpanded(true); // Always expand when editing
  };

  // Handle edit cancellation
  const handleCancel = () => {
    setEditedDraft(draft);
    setEditMode(false);
  };

  // Handle draft field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedDraft(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle saving draft as a new post
  const handleSaveAsPost = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const postData = {
        ...editedDraft,
        userId: auth.currentUser.uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        fromGroupDraft: true,
        originalDraftId: draftId,
        groupId: groupId,
        likes: [],
        comments: [],
        shares: 0,
      };

      // Remove draft-specific fields
      delete postData.type;
      delete postData.expiresAt;

      // Add to posts collection
      const postsRef = collection(db, 'posts');
      await addDoc(postsRef, postData);

      // Close edit mode and collapse after saving
      setEditMode(false);
      setIsExpanded(false);
    } catch (error) {
      console.error('Error saving draft as post:', error);
      setError(t('drafts.save_error'));
    } finally {
      setIsLoading(false);
    }
  };

  if (!draft) {
    return null;
  }

  const renderContent = () => {
    if (editMode) {
      return (
        <div className="draft-edit-form">
          <input
            name="name"
            value={editedDraft.name}
            onChange={handleChange}
            placeholder={t('posts.post_title')}
            className="draft-input"
          />
          <textarea
            name="description"
            value={editedDraft.description}
            onChange={handleChange}
            placeholder={t('posts.post_description')}
            className="draft-textarea"
          />
          <input
            name="link"
            value={editedDraft.link || ''}
            onChange={handleChange}
            placeholder={t('posts.post_link')}
            className="draft-input"
          />
          <select
            name="privacy"
            value={editedDraft.privacy}
            onChange={handleChange}
            className="draft-select"
          >
            <option value="public">{t('posts.privacy.public')}</option>
            <option value="friends">{t('posts.privacy.friends')}</option>
            <option value="private">{t('posts.privacy.private')}</option>
          </select>
          {editedDraft.searchMetadata && (
            <div className="draft-tags">
              {editedDraft.searchMetadata.map((tag, index) => (
                <span key={index} className="tag">
                  {tag}
                </span>
              ))}
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="draft-content">
        <ReactMarkdown>{draft.description}</ReactMarkdown>
        {draft.link && (
          <a 
            href={draft.link}
            target="_blank"
            rel="noopener noreferrer"
            className="draft-link"
          >
            {draft.link}
          </a>
        )}
        {draft.searchMetadata && (
          <div className="draft-tags">
            {draft.searchMetadata.map((tag, index) => (
              <span key={index} className="tag">
                {tag}
              </span>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="group-draft-item">
      <div className="draft-header" onClick={handleToggleExpand}>
        <div className="draft-title">
          <h3>{draft.name}</h3>
          <span className="draft-timestamp">
            {new Date(draft.createdAt.toDate()).toLocaleDateString()}
          </span>
        </div>
        <button 
          className="expand-button"
          aria-label={isExpanded ? t('common.collapse') : t('common.expand')}
        >
          {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
      </div>

      {isExpanded && (
        <div className="draft-details">
          {renderContent()}
          
          <div className="draft-actions">
            {editMode ? (
              <>
                <Button
                  onClick={handleCancel}
                  variant="secondary"
                  size="small"
                >
                  <X className="w-4 h-4 mr-2" />
                  {t('common.cancel')}
                </Button>
                <Button
                  onClick={handleSaveAsPost}
                  disabled={isLoading}
                  variant="primary"
                  size="small"
                >
                  <Save className="w-4 h-4 mr-2" />
                  {isLoading ? t('drafts.saving') : t('drafts.save_as_post')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleEdit}
                  variant="secondary"
                  size="small"
                >
                  <Edit className="w-4 h-4 mr-2" />
                  {t('drafts.edit')}
                </Button>
                <Button
                  onClick={handleSaveAsPost}
                  disabled={isLoading}
                  variant="primary"
                  size="small"
                >
                  <Save className="w-4 h-4 mr-2" />
                  {isLoading ? t('drafts.saving') : t('drafts.save_as_post')}
                </Button>
              </>
            )}
          </div>

          {error && (
            <div className="error-message">
              {error}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GroupDraftItem;