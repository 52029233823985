// src/components/ProfileImageUpload.js

import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-easy-crop';
import Button from './Button';
import './ProfileImageUpload.css';

const ProfileImageUpload = ({ initialImage, onImageSelect }) => {
  const { t } = useTranslation();
  const [image, setImage] = useState(initialImage || null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [showCropModal, setShowCropModal] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = 'anonymous'; // This is important for cross-origin images
      image.onload = () => resolve(image);
      image.onerror = (error) => reject(error);
      image.src = url;
    });

  const resizeImage = (image, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      let { width, height } = image;

      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(image, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            console.error('Canvas is empty');
          }
        },
        'image/jpeg',
        0.8 // Adjust quality as needed
      );
    });
  };

  const handleFileSelect = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = async () => {
        try {
          const img = await createImage(reader.result);
          const resizedBlob = await resizeImage(img, 1024, 1024); // Adjust max dimensions as needed
          const resizedURL = URL.createObjectURL(resizedBlob);
          setImage(resizedURL);
          setShowCropModal(true);
        } catch (error) {
          console.error('Error processing image:', error);
        }
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };

      reader.readAsDataURL(file);
    }
  };

  const getCroppedImg = useCallback(async (imageSrc, pixelCrop) => {
    try {
      const image = await createImage(imageSrc);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
  
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
  
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );
  
      return new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error('Canvas is empty'));
            }
          },
          'image/jpeg',
          0.8 // Adjust quality as needed
        );
      });
    } catch (error) {
      console.error('Error in getCroppedImg:', error);
      throw error;
    }
  }, []);  

  const handleCropConfirm = useCallback(async () => {
    if (!croppedAreaPixels) {
      console.error('No cropped area pixels');
      return;
    }
  
    try {
      const croppedImageBlob = await getCroppedImg(image, croppedAreaPixels);
      const croppedImageUrl = URL.createObjectURL(croppedImageBlob);
      setImage(croppedImageUrl);
      onImageSelect(croppedImageBlob); // Pass the blob to the parent component
      setShowCropModal(false);
    } catch (error) {
      console.error('Error in handleCropConfirm:', error);
    }
  }, [croppedAreaPixels, image, onImageSelect, getCroppedImg]);
  
  return (
    <div className="profile-image-upload">
      <div className="image-preview-container">
        {image ? (
          <img src={image} alt={t('profile.preview_alt')} className="image-preview" />
        ) : (
          <div className="image-placeholder">
            <span>{t('profile.upload_image')}</span>
          </div>
        )}
      </div>
      <input
        type="file"
        onChange={handleFileSelect}
        accept="image/*"
        id="file-input"
        className="file-input"
      />
      <label htmlFor="file-input" className="file-input-label">
        {image ? t('profile.change_picture') : t('profile.upload_picture')}
      </label>

      {showCropModal && (
        <div className="crop-modal">
          <div className="crop-container">
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              minZoom={1}
              maxZoom={3} // Adjust as needed
              cropShape="round" // Optional: to show a circular crop area
            />
          </div>
          <div className="crop-controls">
            <Button onClick={() => setShowCropModal(false)} variant="secondary">
              {t('common.cancel')}
            </Button>
            <Button onClick={handleCropConfirm} variant="primary">
              {t('common.choose')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileImageUpload;
