// src/components/ImageUploader.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import { storage, auth } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { compressImage } from '../utils/imageCompression';

const ImageUploader = ({ onImageUpload, fileInputRef, currentImageCount, maxImages, groupId }) => {
  const { t } = useTranslation();

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      // Calculate how many images can still be uploaded
      const availableSlots = maxImages - currentImageCount;
      if (availableSlots <= 0) {
        alert(t('chat.max_images_reached', { max: maxImages }));
        return;
      }

      // Limit the number of files the user can upload at once
      const filesToUpload = Array.from(files).slice(0, availableSlots);

      try {
        const user = auth.currentUser;
        if (!user) throw new Error(t('errors.user_not_authenticated'));

        // Process each file
        const uploadPromises = filesToUpload.map(async (file) => {
          const compressedFile = await compressImage(file);
          const fileName = `${Date.now()}_${file.name}`;

          // Determine the storage path based on whether it's a group chat or solo chat
          const storagePath = groupId 
            ? `group_chat_images/${groupId}/${fileName}`
            : `chat_images/${user.uid}/${fileName}`;

          const storageRefPath = ref(storage, storagePath);

          // Define custom metadata
          const customMetadata = {
            uploaderId: user.uid,
            uploadTime: new Date().toISOString(),
          };
          
          // Upload the file with metadata
          await uploadBytes(storageRefPath, compressedFile, { customMetadata });
          const downloadURL = await getDownloadURL(storageRefPath);
          return { url: downloadURL, type: file.type, name: fileName, metadata: customMetadata };
        });
        
        // Wait for all uploads to complete
        const uploadedImages = await Promise.all(uploadPromises);
        onImageUpload(uploadedImages);
      } catch (error) {
        console.error('Error uploading images:', error);
        alert(t('errors.image_upload_failed'));
      } finally {
        // Reset the file input value to allow re-uploading the same file if needed
        event.target.value = '';
      }
    }
  };

  return (
    <input
      type="file"
      ref={fileInputRef}
      onChange={handleFileChange}
      accept="image/*"
      multiple // Allow multiple files
      style={{ display: 'none' }}
      aria-label={t('chat.upload_images')}
    />
  );
};

export default ImageUploader;