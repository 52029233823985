import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import './ConfirmationModal.css';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
  const { t } = useTranslation();
  
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>{message}</p>
        <div className="modal-actions">
          <Button onClick={onClose} variant="secondary" size="small">{t('common.cancel')}</Button>
          <Button onClick={onConfirm} variant="primary" size="small">{t('common.confirm')}</Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;