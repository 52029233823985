// src/components/NotificationHandler.js

import React, { useState, useEffect } from 'react';
import { onMessage } from "firebase/messaging";
import { messaging } from '../firebase';
import NotificationBanner from './NotificationBanner';

const NotificationHandler = () => {
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    console.log('Setting up notification handler');
    if (!messaging) {
      console.log('Firebase Messaging is not supported on this browser.');
      return;
    }
  
    try {
      const unsubscribe = onMessage(messaging, (payload) => {
        console.log('Handling new notification:', payload);
        const { data } = payload;
        setNotification({
          title: data.title,
          body: data.body,
          type: data.type,
          groupId: data.groupId,
          notificationId: data.notificationId,
        });
      });
  
      return () => {
        console.log('Cleaning up notification handler');
        if (unsubscribe && typeof unsubscribe === 'function') {
          unsubscribe();
        }
      };
    } catch (error) {
      console.error('Error setting up message listener:', error);
    }
  }, []);

  const handleDismiss = () => {
    setNotification(null);
  };

  if (!notification) return null;

  return (
    <NotificationBanner 
      notification={notification} 
      onDismiss={handleDismiss}
    />
  );
};

export default NotificationHandler;