// src/components/GroupChatPostItem.js

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Heart, MessageCircle, Share2, ChevronDown, ChevronUp, Send, Bookmark } from 'lucide-react';
import Button from './Button';
import DefaultAvatar from './DefaultAvatar';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { usePost } from '../hooks/usePost';
import './GroupChatPostItem.css';

const GroupChatPostItem = ({ post, onPostUpdated }) => {
  const { t } = useTranslation();
  const currentUser = auth.currentUser;
  const { likePost, unlikePost, commentOnPost, sharePost, savePost, unsavePost } = usePost();
  
  // State management
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [likesCount, setLikesCount] = useState(post.likes?.length || 0);
  const [sharesCount, setSharesCount] = useState(post.shares || 0);
  const [isCommentSectionVisible, setIsCommentSectionVisible] = useState(false);
  const [commentInput, setCommentInput] = useState('');
  const [shareMessage, setShareMessage] = useState('');
  const [commenters, setCommenters] = useState({});
  const [visibleComments, setVisibleComments] = useState([]);
  const [commentsPage, setCommentsPage] = useState(1);
  
  const commentsPerPage = 5;

  // Initialize states based on current user and post data
  useEffect(() => {
    if (currentUser && post.likes) {
      setIsLiked(post.likes.includes(currentUser.uid));
    }
  }, [currentUser, post.likes]);

  useEffect(() => {
    setLikesCount(post.likes?.length || 0);
  }, [post.likes]);

  useEffect(() => {
    setSharesCount(post.shares || 0);
  }, [post.shares]);

  useEffect(() => {
    const fetchSavedStatus = async () => {
      if (!currentUser) return;
      
      const userRef = doc(db, 'users', currentUser.uid);
      const userSnap = await getDoc(userRef);
      const userData = userSnap.data();
      setIsSaved(userData.savedPosts?.includes(post.id));
    };
    
    fetchSavedStatus();
  }, [currentUser, post.id]);

  // Fetch commenter information
  useEffect(() => {
    const fetchCommenters = async () => {
      const commenterIds = [...new Set(post.comments?.map(comment => comment.userId) || [])];
      const commenterData = {};
      
      for (const id of commenterIds) {
        const userDoc = await getDoc(doc(db, 'users', id));
        if (userDoc.exists()) {
          commenterData[id] = userDoc.data().displayName || t('common.anonymous');
        } else {
          commenterData[id] = t('common.anonymous');
        }
      }
      
      setCommenters(commenterData);
    };

    fetchCommenters();
  }, [post.comments, t]);

  // Update visible comments based on pagination
  useEffect(() => {
    setVisibleComments(post.comments?.slice(0, commentsPage * commentsPerPage) || []);
  }, [post.comments, commentsPage]);

  const handleLike = useCallback(async () => {
    if (!currentUser) return;

    // Optimistically update the state
    setIsLiked(prev => !prev);
    setLikesCount(prev => isLiked ? prev - 1 : prev + 1);

    try {
      const updatedPost = isLiked
        ? await unlikePost(post.id)
        : await likePost(post.id);
      
      onPostUpdated?.(updatedPost);
    } catch (error) {
      console.error('Error updating likes:', error);
      // Revert state changes on error
      setIsLiked(prev => !prev);
      setLikesCount(prev => isLiked ? prev + 1 : prev - 1);
    }
  }, [currentUser, isLiked, post.id, likePost, unlikePost, onPostUpdated]);

  const handleSave = useCallback(async () => {
    if (!currentUser) return;

    // Optimistically update the state
    setIsSaved(prev => !prev);

    try {
      if (isSaved) {
        await unsavePost(post.id);
      } else {
        await savePost(post.id);
      }
    } catch (error) {
      console.error('Error updating saved posts:', error);
      // Revert state changes on error
      setIsSaved(prev => !prev);
    }
  }, [currentUser, isSaved, post.id, savePost, unsavePost]);

  const handleShare = useCallback(async () => {
    // Optimistically update the state
    setSharesCount(prev => prev + 1);

    try {
      const updatedPost = await sharePost(post.id);
      onPostUpdated?.(updatedPost);

      const shareableLink = `${window.location.origin}/post/${post.id}`;
      
      if (navigator.share) {
        await navigator.share({
          title: post.name,
          text: post.description,
          url: shareableLink,
        });
        setShareMessage(t('posts.share_success'));
      } else {
        await navigator.clipboard.writeText(shareableLink);
        setShareMessage(t('posts.link_copied'));
      }
    } catch (error) {
      console.error('Error sharing post:', error);
      // Revert state changes on error
      setSharesCount(prev => prev - 1);
      setShareMessage(t('posts.share_error'));
    }

    setTimeout(() => setShareMessage(''), 3000);
  }, [post.id, post.name, post.description, sharePost, onPostUpdated, t]);

  const handleComment = useCallback(async (e) => {
    e.preventDefault();
    if (!currentUser || !commentInput.trim()) return;

    // Optimistically update the UI
    const newComment = {
      userId: currentUser.uid,
      content: commentInput.trim(),
      createdAt: new Date().toISOString(),
      replies: []
    };
    const updatedComments = [...(post.comments || []), newComment];
    onPostUpdated?.({ ...post, comments: updatedComments });
    setCommentInput('');

    try {
      const updatedPost = await commentOnPost(post.id, commentInput.trim());
      onPostUpdated?.(updatedPost);
    } catch (error) {
      console.error('Error adding comment:', error);
      // Revert state changes on error
      onPostUpdated?.(post); // Reverts to the original post without the new comment
    }
  }, [currentUser, commentInput, post, onPostUpdated, commentOnPost]);

  const formatDate = (dateString) => {
    try {
      if (dateString?.seconds) {
        return format(new Date(dateString.seconds * 1000), 'MMM d, yyyy HH:mm');
      }
      return format(new Date(dateString), 'MMM d, yyyy HH:mm');
    } catch (error) {
      console.error('Error formatting date:', error);
      return t('common.invalid_date');
    }
  };

  const loadMoreComments = useCallback(() => {
    setCommentsPage(prev => prev + 1);
  }, []);

  return (
    <div className="chat-post-item">
      <div className="post-header" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="post-header-content">
          <div className="post-author">
            <Link to={`/profile/${post.author?.id}`} onClick={e => e.stopPropagation()}>
              {post.author?.profilePictureUrl ? (
                <img
                  src={post.author.profilePictureUrl}
                  alt={post.author?.displayName || t('common.anonymous')}
                  className="author-avatar"
                />
              ) : (
                <DefaultAvatar
                  name={post.author?.displayName || t('common.anonymous')}
                  size={36}
                  fallbackColor="var(--color-primary-light)"
                  textColor="var(--color-surface)"
                />
              )}
            </Link>
            <div className="author-info">
              <h5 className="author-name">{post.author
                ? `${post.author.displayName} (@${post.author.username})`
                : t('common.anonymous')}
              </h5>
              <h4 className="post-title">{post.name}</h4>
            </div>
          </div>
          {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </div>
      </div>

      {isExpanded && (
        <div className="post-content">
          <div className="post-description">
            <ReactMarkdown>{post.description}</ReactMarkdown>
          </div>

          {post.link && (
            <a
              href={post.link}
              target="_blank"
              rel="noopener noreferrer"
              className="post-link"
            >
              {post.link}
            </a>
          )}

          <div className="post-meta">
            <span className="post-date">{formatDate(post.createdAt)}</span>
          </div>

          <div className="post-actions">
            <Button
              variant="tertiary"
              size="small"
              aria-label={isLiked ? t('posts.unlike_post') : t('posts.like_post')}
              onClick={handleLike}
              className="action-button"
            >
              <Heart fill={isLiked ? 'var(--color-primary)' : 'none'} />
              <span>{likesCount}</span>
            </Button>

            <Button
              variant="tertiary"
              size="small"
              aria-label={isCommentSectionVisible ? t('posts.hide_comments') : t('posts.show_comments')}
              onClick={() => setIsCommentSectionVisible(!isCommentSectionVisible)}
              className="action-button"
            >
              <MessageCircle />
              <span>{post.comments?.length || 0}</span>
            </Button>

            <Button
              variant="tertiary"
              size="small"
              aria-label={t('posts.share_post')}
              onClick={handleShare}
              className="action-button"
            >
              <Share2 />
              <span>{sharesCount}</span>
            </Button>

            <Button
              variant="tertiary"
              size="small"
              aria-label={isSaved ? t('posts.unsave_post') : t('posts.save_post')}
              onClick={handleSave}
              className="action-button"
            >
              <Bookmark fill={isSaved ? 'var(--color-primary)' : 'none'} />
            </Button>
          </div>

          {shareMessage && (
            <div className="share-message">{shareMessage}</div>
          )}

          {isCommentSectionVisible && (
            <div className="comments-section">
              <h5>{t('comments.title')}</h5>
              
              <div className="comments-list">
                {visibleComments.map((comment, index) => (
                  <div key={index} className="comment">
                    <p>
                      <strong>{commenters[comment.userId] || t('common.anonymous')}: </strong>
                      {comment.content}
                    </p>
                    <small>{formatDate(comment.createdAt)}</small>
                  </div>
                ))}
              </div>

              {post.comments?.length > visibleComments.length && (
                <Button
                  variant="secondary"
                  size="small"
                  onClick={loadMoreComments}
                  className="load-more-button"
                >
                  {t('comments.load_more')}
                </Button>
              )}

              <form onSubmit={handleComment} className="comment-form">
                <input
                  type="text"
                  value={commentInput}
                  onChange={(e) => setCommentInput(e.target.value)}
                  placeholder={t('comments.add_comment_placeholder')}
                  className="comment-input"
                />
                <Button type="submit" variant="primary" size="small" className="send-button">
                  <Send size={16} />
                </Button>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GroupChatPostItem;