// src/components/GroupList.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UsersRound, Plus, Search } from 'lucide-react';
import { auth, db } from '../firebase';
import { collection, doc, getDoc, onSnapshot, query, where, orderBy } from 'firebase/firestore';
import './GroupList.css';

const GroupList = ({ onCreateGroup }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [groups, setGroups] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchGroups = async () => {
      if (!auth.currentUser) return;
  
      const groupsRef = collection(db, 'groups');
      const q = query(
        groupsRef,
        where('members', 'array-contains', auth.currentUser.uid),
        orderBy('lastActivityAt', 'desc')
      );
  
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const groupsData = await Promise.all(
          querySnapshot.docs.map(async (docSnap) => {
            const groupData = { id: docSnap.id, ...docSnap.data() };
  
            // Fetch lastReadAt and unreadCount from the member document
            const memberRef = doc(db, `groups/${groupData.id}/members/${auth.currentUser.uid}`);
            const memberSnap = await getDoc(memberRef);
  
            const lastReadAt = memberSnap.exists()
              ? memberSnap.data().lastReadAt?.toDate() || memberSnap.data().joinedAt?.toDate() || new Date(0)
              : new Date(0);
  
            const unreadCount = memberSnap.exists() ? memberSnap.data().unreadCount || 0 : 0;
  
            return {
              ...groupData,
              lastReadAt,
              unreadCount,
            };
          })
        );
  
        setGroups(groupsData);
      });
  
      return () => unsubscribe();
    };
  
    fetchGroups();
  }, []);

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const formatLastActive = (date) => {
    if (!date) return 'Never';
    const now = new Date();
    const diff = now - date;
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days}d`;
    if (hours > 0) return `${hours}h`;
    if (minutes > 0) return `${minutes}m`;
    return 'Just now';
  };

  const filteredGroups = groups.filter(group =>
    group.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="group-list">
      <div className="group-list-header">
        <div className="header-content">
          <div className="header-title">
            <UsersRound className="title-icon" />
            <h1>{t('chat.groups')}</h1>
          </div>
          <button
            onClick={onCreateGroup}
            className="create-group-button"
            aria-label="Create new group"
          >
            <Plus />
          </button>
        </div>

        <div className="search-container">
          <Search className="search-icon" />
          <input
            type="text"
            placeholder={t('groups.search_groups')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
        </div>
      </div>        

      <div className="group-list-content">
        {filteredGroups.map((group) => (
          <button
            key={group.id}
            onClick={() => navigate(`/groups/${group.id}`)}
            className={`group-item ${group.unreadCount > 0 ? 'unread' : ''}`}
          >
            <div className="group-avatar">
              {group.icon ? (
                <img
                  src={group.icon}
                  alt={group.name}
                  className="avatar-image"
                />
              ) : (
                <div className={`avatar-initials ${group.unreadCount > 0 ? 'unread' : ''}`}>
                  {getInitials(group.name)}
                </div>
              )}
            </div>

            <div className="group-details">
              <div className="group-details-top">
                <h3 className="group-name">{group.name}</h3>
                <span className="last-active">
                  {formatLastActive(group.lastMessageAt?.toDate())}
                </span>
              </div>
              <div className="group-details-bottom">
                <p className="last-message">
                  {group.lastMessage}
                </p>
                {group.unreadCount > 0 && (
                  <span className="unread-badge">
                    {group.unreadCount}
                  </span>
                )}
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default GroupList;