// src/components/Auth.js

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { auth } from '../firebase';
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { FaGoogle, FaEnvelope, FaPhone, FaSignInAlt, FaUserPlus, FaArrowLeft } from 'react-icons/fa';
import Button from './Button';
import './Auth.css';

const Auth = ({ onAuthSuccess }) => {
  const { t } = useTranslation();

  // State variables
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isPhoneInput, setIsPhoneInput] = useState(true);
  const [verificationCode, setVerificationCode] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isPasswordStep, setIsPasswordStep] = useState(false);
  const [password, setPassword] = useState('');
  const [isResetEmailSent, setIsResetEmailSent] = useState(false);

  // Get default country code based on user's locale
  let defaultCountry = 'US'; // Default to US if country code not found
  const locale = Intl.DateTimeFormat().resolvedOptions().locale; // e.g., 'en-US'
  if (locale && locale.includes('-')) {
    const countryCode = locale.split('-')[1].toUpperCase(); // Extract 'US' from 'en-US'
    defaultCountry = countryCode;
  }

  // Initialize Recaptcha
  const setupRecaptcha = () => {
    const recaptchaContainer = document.getElementById('recaptcha-container');
    if (!recaptchaContainer) {
      console.error('recaptcha-container not found');
      return;
    }

    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'invisible',
        'callback': () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
      });
    }
  };

  // Handle 'Continue' Button Click
  const handleContinue = async (e) => {
    e.preventDefault();
    setError('');
    setIsResetEmailSent(false);

    if (isPhoneInput) {
      // Phone Authentication
      if (!phoneNumber) {
        setError(t('auth.invalid_input', { field: t('auth.phone_number') }));
        return;
      }
      setIsLoading(true);
      setupRecaptcha();
      try {
        const confirmation = await signInWithPhoneNumber(
          auth,
          phoneNumber,
          window.recaptchaVerifier
        );
        setConfirmationResult(confirmation);
      } catch (error) {
        console.error("Error sending verification code:", error);
        setError(t('auth.verification_code_send_error'));
      } finally {
        setIsLoading(false);
      }
    } else {
      // Email Authentication
      if (!validateEmail(email)) {
        setError(t('auth.invalid_input', { field: t('auth.email_address') }));
        return;
      }
      setIsLoading(true);
      setIsPasswordStep(true);
      setIsRegistering(false); // Assume user wants to sign in initially
      setIsLoading(false);
    }
  };

  // Handle Password Submission
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsResetEmailSent(false);

    if (!password) {
      setError(t('auth.invalid_input', { field: t('auth.password') }));
      return;
    }

    setIsLoading(true);
    try {
      // Attempt to sign in the user
      const userCredential = await signInWithEmailAndPassword(auth, email.toLowerCase(), password);
      onAuthSuccess(userCredential.user);
    } catch (error) {
      console.error("Error with email sign-in:", error);
      if (error.code === 'auth/user-not-found') {
        // If user not found, prompt to register
        setIsRegistering(true);
        setError(t('auth.no_account_found'));
      } else if (error.code === 'auth/wrong-password') {
        setError(t('auth.incorrect_password'));
      } else {
        setError(t('auth.authentication_failed'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Handle Registration Submission
  const handleRegistration = async (e) => {
    e.preventDefault();
    setError('');
    setIsResetEmailSent(false);

    if (!password) {
      setError(t('auth.create_password_prompt'));
      return;
    }

    setIsLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email.toLowerCase(), password);
      onAuthSuccess(userCredential.user);
    } catch (error) {
      console.error("Error with email registration:", error);
      if (error.code === 'auth/email-already-in-use') {
        setError(t('auth.email_already_in_use'));
      } else {
        setError(t('auth.registration_failed'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Handle Verification Code Submission
  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setError('');
    setIsResetEmailSent(false);

    if (!confirmationResult) {
      setError(t('auth.no_confirmation_result'));
      return;
    }
    setIsLoading(true);
    try {
      const userCredential = await confirmationResult.confirm(verificationCode);
      onAuthSuccess(userCredential.user);
    } catch (error) {
      console.error("Error verifying code:", error);
      setError(t('auth.invalid_verification_code'));
    } finally {
      setIsLoading(false);
    }
  };

  // Handle Google Sign-In
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    setIsGoogleLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      onAuthSuccess(result.user);
    } catch (error) {
      if (error.code === 'auth/popup-closed-by-user') {
        // Handle case when the popup is closed by the user
        console.log('Google sign-in popup closed by user.');
        setError(t('auth.social_sign_in_canceled', { provider: 'Google' }));
      } else {
        console.error("Error with Google sign-in:", error);
        setError(t('auth.social_sign_in_failed', { provider: 'Google' }));
      }
    } finally {
      setIsGoogleLoading(false);
    }
  };

  // Validate Email Function
  const validateEmail = (email) => {
    // Simple email regex validation
    return /\S+@\S+\.\S+/.test(email);
  };

  // Handle Toggle Between Phone and Email Authentication
  const toggleAuthMethod = () => {
    setIsPhoneInput(!isPhoneInput);
    setPhoneNumber('');
    setEmail('');
    setError('');
    setIsPasswordStep(false);
    setIsRegistering(false);
    setPassword('');
    setIsResetEmailSent(false);
  };

  // Handle Back Button Click
  const handleBack = () => {
    if (confirmationResult) {
      // If in the verification code step
      setConfirmationResult(null);
      setVerificationCode('');
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear(); // Clear the existing verifier
        window.recaptchaVerifier = null; // Reset to allow re-initialization
      }
    } else if (isPasswordStep || isRegistering) {
      // If in the email password or registration step
      setIsPasswordStep(false);
      setIsRegistering(false);
      setPassword('');
    }
    setError('');
    setIsResetEmailSent(false);
  };

  // Handle Forgot Password
  const handleForgotPassword = async () => {
    setError('');
    setIsResetEmailSent(false);

    if (!validateEmail(email)) {
      setError(t('auth.invalid_input', { field: t('auth.email_address') }));
      return;
    }

    setIsLoading(true);
    try {
      await sendPasswordResetEmail(auth, email.toLowerCase());
      setIsResetEmailSent(true);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      if (error.code === 'auth/user-not-found') {
        setError(t('auth.no_account_found'));
      } else {
        setError(t('auth.reset_email_send_error'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="auth-container">
      {/* Header with Back Button */}
      {(isPasswordStep || isRegistering || confirmationResult) && (
        <header className="auth-header">
          <button
            type="button"
            onClick={handleBack}
            className="back-button"
            aria-label={t('common.back')}
          >
            <FaArrowLeft />
          </button>
          <h2 className="auth-header-title">{t('auth.authentication')}</h2>
        </header>
      )}

      {/* Default Header */}
      {!(isPasswordStep || isRegistering || confirmationResult) && (
        <header className="auth-header">
          <h2 className="auth-header-title">{t('auth.welcome')}</h2>
        </header>
      )}

      {!confirmationResult ? (
        !isPasswordStep ? (
          <form onSubmit={handleContinue} className="auth-form">
            {isPhoneInput ? (
              <>
                <div className="input-group">
                  <label htmlFor="phone-input" className="input-label">{t('auth.phone_number')}</label>
                  <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry={defaultCountry}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    className="phone-input"
                    id="phone-input"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="input-group">
                  <label htmlFor="email-input" className="input-label">{t('auth.email_address')}</label>
                  <input
                    type="email"
                    id="email-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t('auth.enter_email')}
                    className="auth-input"
                    required
                  />
                </div>
              </>
            )}
            <Button
              type="submit"
              variant="primary"
              size="large"
              disabled={isLoading || (isPhoneInput ? !phoneNumber : !email)}
            >
              {isLoading ? t('auth.processing') : t('auth.continue')}
            </Button>
            {error && <p className="auth-error" role="alert">{error}</p>}
            <div className="separator">
              <span>{t('auth.or')}</span>
            </div>
            {/* Button Group for Google Sign-In and Toggle */}
            <div className="button-group">
              <Button
                onClick={handleGoogleSignIn}
                variant="secondary"
                size="large"
                disabled={isGoogleLoading}
                className="google-button"
              >
                <FaGoogle style={{ marginRight: '8px' }} />
                {isGoogleLoading ? t('auth.processing') : t('auth.continue_with_google')}
              </Button>
              {isPhoneInput ? (
                <Button
                  type="button"
                  onClick={toggleAuthMethod}
                  variant="secondary"
                  size="large"
                  className="toggle-button"
                  disabled={isLoading}
                >
                  <FaEnvelope style={{ marginRight: '8px' }} />
                  {t('auth.continue_with_email')}
                </Button>
              ) : (
                <Button
                  type="button"
                  onClick={toggleAuthMethod}
                  variant="secondary"
                  size="large"
                  className="toggle-button"
                  disabled={isLoading}
                >
                  <FaPhone style={{ marginRight: '8px' }} />
                  {t('auth.continue_with_phone')}
                </Button>
              )}
            </div>
            <div id="recaptcha-container"></div>
          </form>
        ) : isPhoneInput ? (
          <form onSubmit={handleVerifyCode} className="auth-form">
            <div className="input-group">
              <label htmlFor="verification-code" className="input-label">{t('auth.enter_verification_code')}</label>
              <input
                id="verification-code"
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder={t('auth.six_digit_code')}
                className="auth-input"
                required
              />
            </div>
            <Button type="submit" variant="primary" size="large" disabled={isLoading}>
              {isLoading ? t('auth.verifying') : t('auth.verify_code')}
            </Button>
            {error && <p className="auth-error" role="alert">{error}</p>}
          </form>
        ) : (
          <form onSubmit={isRegistering ? handleRegistration : handlePasswordSubmit} className="auth-form">
            <div className="input-group">
              <label htmlFor="password-input" className="input-label">
                {isRegistering ? t('auth.create_password') : t('auth.enter_password')}
              </label>
              <input
                type="password"
                id="password-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={isRegistering ? t('auth.create_password_placeholder') : t('auth.enter_password_placeholder')}
                className="auth-input"
                required
              />
            </div>
            <Button type="submit" variant="primary" size="large" disabled={isLoading}>
              {isLoading
                ? isRegistering
                  ? t('auth.registering')
                  : t('auth.signing_in')
                : isRegistering
                ? t('auth.register')
                : t('auth.sign_in')}
            </Button>
            {!isRegistering && (
              <button
                type="button"
                onClick={handleForgotPassword}
                className="forgot-password-link"
                disabled={isLoading}
              >
                {t('auth.forgot_password')}
              </button>
            )}
            {isResetEmailSent && (
              <p className="auth-info">
                {t('auth.reset_email_sent', { email })}
              </p>
            )}
            {error && <p className="auth-error" role="alert">{error}</p>}
            {/* Button Group for Toggle Sign In/Register */}
            <div className="button-group">
              {isRegistering ? (
                <Button
                  type="button"
                  onClick={() => { setIsRegistering(false); setPassword(''); }}
                  variant="secondary"
                  size="large"
                  className="toggle-button"
                  disabled={isLoading}
                >
                  <FaSignInAlt style={{ marginRight: '8px' }} />
                  {t('auth.sign_in')}
                </Button>
              ) : (
                <Button
                  type="button"
                  onClick={() => { setIsRegistering(true); setPassword(''); }}
                  variant="secondary"
                  size="large"
                  className="toggle-button"
                  disabled={isLoading}
                >
                  <FaUserPlus style={{ marginRight: '8px' }} />
                  {t('auth.register')}
                </Button>
              )}
            </div>
          </form>
        )
      ) : (
        <form onSubmit={handleVerifyCode} className="auth-form">
          <div className="input-group">
            <label htmlFor="verification-code" className="input-label">{t('auth.enter_verification_code')}</label>
            <input
              id="verification-code"
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder={t('auth.six_digit_code')}
              className="auth-input"
              required
            />
          </div>
          <Button type="submit" variant="primary" size="large" disabled={isLoading}>
            {isLoading ? t('auth.verifying') : t('auth.verify_code')}
          </Button>
          {error && <p className="auth-error" role="alert">{error}</p>}
        </form>
      )}
    </div>
  );
};

export default Auth;