// src/components/FriendItem.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultAvatar from './DefaultAvatar';
import styles from './FriendItem.module.css';

const FriendItem = ({ friend, shouldHighlight, onClick }) => {
  const { t } = useTranslation();

  const handleFriendClick = () => {
    onClick(friend.id);
  };

  const getDisplayName = () => {
    if (friend.displayName) return friend.displayName;
    if (friend.username) return friend.username;
    return t('friends.anonymous_friend');
  };

  return (
    <li
      className={`${styles.friendItem} ${shouldHighlight ? styles.highlight : ''}`}
      onClick={handleFriendClick}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleFriendClick();
        }
      }}
      role="button"
      tabIndex="0"
    >
      <div className={styles.friendAvatar}>
        {friend.profilePictureUrl ? (
          <img
            src={friend.profilePictureUrl}
            alt={t('friends.profile_picture_alt', { name: getDisplayName() })}
            className={styles.friendAvatarImage}
            loading="lazy"
          />
        ) : (
          <DefaultAvatar
            name={getDisplayName()}
            size={50}
            fallbackColor="var(--color-primary-light)"
            textColor="var(--color-surface)"
          />
        )}
      </div>
      <div className={styles.friendInfo}>
        <div className={styles.friendName}>{getDisplayName()}</div>
        {friend.username && (
          <div className={styles.friendUsername}>@{friend.username}</div>
        )}
      </div>
    </li>
  );
};

export default FriendItem;