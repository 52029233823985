// src/components/AddFriendByUsername.js

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { auth, db } from '../firebase';
import { collection, query, where, getDocs, setDoc, doc, arrayUnion } from 'firebase/firestore';
import Button from './Button';

const AddFriendByUsername = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddFriend = async (e) => {
    e.preventDefault();
    setMessage('');
    setIsLoading(true);

    if (!username.trim()) {
      setMessage(t('friends.invalid_username'));
      setIsLoading(false);
      return;
    }

    const currentUser = auth.currentUser;

    try {
      const q = query(collection(db, "users"), where("username", "==", username.trim()));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setMessage(t('friends.no_user_found_username'));
        setIsLoading(false);
        return;
      }

      const friendDoc = querySnapshot.docs[0];
      const friendId = friendDoc.id;

      if (friendId === currentUser.uid) {
        setMessage(t('friends.cannot_add_self'));
        setIsLoading(false);
        return;
      }

      // Send friend request
      await setDoc(doc(db, 'users', friendId), {
        friendRequests: arrayUnion(currentUser.uid)
      }, { merge: true });

      setMessage(t('friends.request_sent'));
      setUsername('');
    } catch (error) {
      console.error("Error sending friend request: ", error);
      if (error.code === 'permission-denied') {
        setMessage(t('errors.permission_denied'));
      } else {
        setMessage(t('friends.request_error'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleAddFriend} className="add-friend-form">
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder={t('friends.enter_username')}
        className="add-friend-input"
        disabled={isLoading}
      />
      <Button 
        type="submit" 
        variant="primary" 
        size="medium"
        disabled={isLoading}
        className="add-friend-button"
      >
        {isLoading ? t('friends.sending_request') : t('friends.send_request')}
      </Button>
      {message && <p className="add-friend-message">{message}</p>}
    </form>
  );
};

export default AddFriendByUsername;