// src/components/NotificationsDropdown.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isToday, isYesterday, isThisWeek } from 'date-fns';
import NotificationItem from './NotificationItem';
import { getNotifications } from '../services/notificationService';
import './NotificationsDropdown.css';

const groupNotifications = (notifications, t) => {
  const groups = {
    [t('notifications.today')]: [],
    [t('notifications.yesterday')]: [],
    [t('notifications.this_week')]: [],
    [t('notifications.earlier')]: []
  };

  notifications.forEach(notification => {
    const date = notification.createdAt && notification.createdAt.toDate ?
      notification.createdAt.toDate() :
      new Date();

    if (isToday(date)) {
      groups[t('notifications.today')].push(notification);
    } else if (isYesterday(date)) {
      groups[t('notifications.yesterday')].push(notification);
    } else if (isThisWeek(date)) {
      groups[t('notifications.this_week')].push(notification);
    } else {
      groups[t('notifications.earlier')].push(notification);
    }
  });

  return groups;
};

const NotificationsDropdown = ({ isOpen, onClose }) => {
  const [notifications, setNotifications] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const fetchedNotifications = await getNotifications();
        setNotifications(fetchedNotifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    if (isOpen) {
      fetchNotifications();
    }
  }, [isOpen]);

  const groupedNotifications = groupNotifications(notifications, t);

  if (!isOpen) return null;

  return (
    <div className="notifications-dropdown">
      <h2 className="notifications-header">{t('notifications.notifications')}</h2>
      {Object.entries(groupedNotifications).map(([group, groupNotifications]) => (
        groupNotifications.length > 0 && (
          <div key={group} className="notification-group">
            <h3 className="group-header">{group}</h3>
            {groupNotifications.map(notification => (
              <NotificationItem 
                key={notification.id} 
                notification={notification} 
                onClose={onClose}
              />
            ))}
          </div>
        )
      ))}
      {notifications.length === 0 && (
        <div className="empty-state">
          <p>{t('notifications.caught_up')}</p>
          <p>{t('notifications.check_back_later')}</p>
        </div>
      )}
    </div>
  );
};

export default NotificationsDropdown;