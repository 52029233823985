import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Button from './Button';
import './PostPreview.css';

const PostPreview = ({ post, onPostCreated, onClose }) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [editedPost, setEditedPost] = useState(post);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Synchronize editedPost with post when not in edit mode
  useEffect(() => {
    if (!editMode) {
      setEditedPost(post);
    }
  }, [post, editMode]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditedPost(post);
    setEditMode(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedPost(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    setError(null);

    try {
      await onPostCreated(editedPost);
      onClose();
    } catch (error) {
      console.error('Error creating post:', error);
      setError(t('posts.create_error'));
    } finally {
      setIsLoading(false);
    }
  };

  if (editMode) {
    return (
      <div className="post-preview edit-mode">
        <input
          name="name"
          value={editedPost.name}
          onChange={handleChange}
          placeholder={t('posts.post_title')}
        />
        <textarea
          name="description"
          value={editedPost.description}
          onChange={handleChange}
          placeholder={t('posts.post_description')}
        />
        <input
          name="link"
          value={editedPost.link}
          onChange={handleChange}
          placeholder={t('posts.post_link')}
        />
        <select
          name="privacy"
          value={editedPost.privacy}
          onChange={handleChange}
        >
          <option value="public">{t('posts.privacy.public')}</option>
          <option value="friends">{t('posts.privacy.friends')}</option>
          <option value="private">{t('posts.privacy.private')}</option>
        </select>
        <div className="search-metadata">
          {editedPost.searchMetadata.map((tag, index) => (
            <span key={index} className="tag">
              {tag}
            </span>
          ))}
        </div>
        <div className="post-preview-actions">
          <Button onClick={handleCancel} variant="secondary" size="small">
            {t('common.cancel')}
          </Button>
          <Button onClick={handleSave} disabled={isLoading} variant="primary" size="small">
            {isLoading ? t('posts.saving') : t('posts.save_post')}
          </Button>
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
    );
  }

  return (
    <div className="post-preview">
      <h3>{post.name}</h3>
      <div className="post-description">
        <ReactMarkdown>{post.description}</ReactMarkdown>
      </div>
      {post.link && (
        <a href={post.link} target="_blank" rel="noopener noreferrer">
          {post.link}
        </a>
      )}
      <p>{t('posts.post_privacy')}: {t(`posts.privacy.${post.privacy}`)}</p>
      <div className="search-metadata">
        {post.searchMetadata.map((tag, index) => (
          <span key={index} className="tag">
            {tag}
          </span>
        ))}
      </div>
      <div className="post-preview-actions">
        <Button onClick={handleEdit} variant="secondary" size="small">
          {t('posts.edit_post')}
        </Button>
        <Button onClick={handleSave} disabled={isLoading} variant="primary" size="small">
          {isLoading ? t('posts.saving') : t('posts.create_post')}
        </Button>
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default PostPreview;