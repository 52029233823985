// src/pages/Friends.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import AddFriend from '../components/AddFriend';
import AddFriendByUsername from '../components/AddFriendByUsername';
import FriendRequests from '../components/FriendRequests';
import FriendItem from '../components/FriendItem';
import '../styles/Friends.css';

const Friends = () => {
  const { t } = useTranslation();
  const [friends, setFriends] = useState([]);
  const [addMethod, setAddMethod] = useState('phone'); // 'phone' or 'username'
  const [friendsLastViewedAt, setFriendsLastViewedAt] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFriends = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();
        const friendIds = userData.friends || [];
        const lastViewedAt = userData.friendsLastViewedAt || {};

        setFriendsLastViewedAt(lastViewedAt);

        const friendsData = await Promise.all(
          friendIds.map(async (friendId) => {
            const friendDoc = await getDoc(doc(db, 'users', friendId));
            return { id: friendId, ...friendDoc.data() };
          })
        );

        // Sort friends based on lastPostUpdatedAt
        friendsData.sort((a, b) => {
          const timeA = a.lastPostUpdatedAt ? a.lastPostUpdatedAt.toMillis() : 0;
          const timeB = b.lastPostUpdatedAt ? b.lastPostUpdatedAt.toMillis() : 0;
          return timeB - timeA; // Descending order
        });

        setFriends(friendsData);
      }
    };

    fetchFriends();
  }, []);

  const handleFriendClick = async (friendId) => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const updatedFriendsLastViewedAt = {
        ...friendsLastViewedAt,
        [friendId]: serverTimestamp(),
      };

      await updateDoc(userDocRef, {
        friendsLastViewedAt: updatedFriendsLastViewedAt,
      });

      // Navigate to the friend's profile
      navigate(`/profile/${friendId}`);
    }
  };

  const shouldHighlightFriend = (friend) => {
    const lastViewed = friendsLastViewedAt[friend.id];
    const lastPostUpdatedAt = friend.lastPostUpdatedAt;

    if (lastPostUpdatedAt && lastViewed) {
      return lastPostUpdatedAt.toMillis() > lastViewed.toMillis();
    } else if (lastPostUpdatedAt && !lastViewed) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="friends-page">
      <div className="friends-header">
      </div>
      <div className="add-friend-section">
        <div className="add-friend-tabs">
          <button
            className={`add-friend-tab ${addMethod === 'phone' ? 'active' : ''}`}
            onClick={() => setAddMethod('phone')}
          >
            {t('friends.add_friend_by_phone')}
          </button>
          <button
            className={`add-friend-tab ${addMethod === 'username' ? 'active' : ''}`}
            onClick={() => setAddMethod('username')}
          >
            {t('friends.add_friend_by_username')}
          </button>
        </div>
        <div className="add-friend-content">
          {addMethod === 'phone' ? <AddFriend /> : <AddFriendByUsername />}
        </div>
      </div>
      <FriendRequests />
      <div className="friends-list">
        <h3>{t('friends.my_friends')}</h3>
        {friends.length === 0 ? (
          <p>{t('friends.no_friends')}</p>
        ) : (
          <ul>
            {friends.map((friend) => (
              <FriendItem
                key={friend.id}
                friend={friend}
                shouldHighlight={shouldHighlightFriend(friend)}
                onClick={handleFriendClick}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Friends;
