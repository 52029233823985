// src/components/ChatPostItem.js
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { format, parseISO } from 'date-fns';
import { Heart, MessageSquare, Share2, ChevronDown, ChevronUp, Send, Bookmark } from 'react-feather';
import Button from './Button';
import { auth, db } from '../firebase';
import { usePost } from '../hooks/usePost';
import { doc, getDoc } from 'firebase/firestore';
import styles from './ChatPostItem.module.css';

const ChatPostItem = ({ post, onPostUpdated, savedPosts, onSavedPostsUpdated, isCommentSectionVisible, onToggleCommentSection }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLiked, setIsLiked] = useState(post.likes.includes(auth.currentUser?.uid));
  const [isSaved, setIsSaved] = useState(savedPosts.includes(post.id));
  const [commentInput, setCommentInput] = useState('');
  const [shareMessage, setShareMessage] = useState('');
  const [commenters, setCommenters] = useState({});
  const [visibleComments, setVisibleComments] = useState([]);
  const [commentsPage, setCommentsPage] = useState(1);
  const commentsPerPage = 5;

  const { likePost, unlikePost, commentOnPost, sharePost, savePost, unsavePost } = usePost();

  useEffect(() => {
    setIsSaved(savedPosts.includes(post.id));
  }, [savedPosts, post.id]);

  useEffect(() => {
    const fetchCommenters = async () => {
      const commenterIds = [...new Set(post.comments.map(comment => comment.userId))];
      const commenterData = {};
      
      for (const id of commenterIds) {
        const userDoc = await getDoc(doc(db, 'users', id));
        if (userDoc.exists()) {
          commenterData[id] = userDoc.data().displayName || t('common.anonymous');
        } else {
          commenterData[id] = t('common.anonymous');
        }
      }
      
      setCommenters(commenterData);
    };

    fetchCommenters();
  }, [post.comments, t]);

  useEffect(() => {
    setVisibleComments(post.comments.slice(0, commentsPage * commentsPerPage));
  }, [post.comments, commentsPage]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLike = useCallback(async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('User must be logged in to like a post');
      return;
    }

    // Optimistic update
    setIsLiked(!isLiked);
    const optimisticPost = {
      ...post,
      likes: isLiked
        ? post.likes.filter(id => id !== currentUser.uid)
        : [...post.likes, currentUser.uid]
    };
    onPostUpdated(optimisticPost);

    try {
      const updatedPost = isLiked
        ? await unlikePost(post.id)
        : await likePost(post.id);
      onPostUpdated(updatedPost);
    } catch (error) {
      console.error('Error toggling like:', error);
      // Revert optimistic update on error
      setIsLiked(!isLiked);
      onPostUpdated(post);
    }
  }, [post, isLiked, likePost, unlikePost, onPostUpdated]);

  const handleComment = useCallback(async (e) => {
    e.preventDefault();
    const currentUser = auth.currentUser;
    if (!currentUser || !commentInput.trim()) {
      return;
    }

    try {
      const updatedPost = await commentOnPost(post.id, commentInput);
      onPostUpdated(updatedPost);
      setCommentInput('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  }, [post.id, commentInput, commentOnPost, onPostUpdated]);

  const handleShare = useCallback(async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('User must be logged in to share a post');
      return;
    }

    try {
      const updatedPost = await sharePost(post.id);
      onPostUpdated(updatedPost);

      const shareableLink = `${window.location.origin}/post/${post.id}`;

      if (navigator.share) {
        await navigator.share({
          title: post.name,
          text: post.description,
          url: shareableLink,
        });
        setShareMessage(t('posts.share_success'));
      } else {
        await navigator.clipboard.writeText(shareableLink);
        setShareMessage(t('posts.link_copied'));
      }
    } catch (error) {
      console.error('Error sharing post:', error);
      setShareMessage(t('posts.share_error'));
    }

    setTimeout(() => setShareMessage(''), 3000);
  }, [post.id, post.name, post.description, sharePost, onPostUpdated, t]);

  const handleSave = useCallback(async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('User must be logged in to save a post');
      return;
    }
  
    // Optimistic update
    setIsSaved(!isSaved);
    const optimisticSavedPosts = isSaved
      ? savedPosts.filter(id => id !== post.id)
      : [...savedPosts, post.id];
    onSavedPostsUpdated(optimisticSavedPosts);
  
    try {
      const updatedUserData = isSaved
        ? await unsavePost(post.id)
        : await savePost(post.id);
      
      // Check if updatedUserData is defined and has savedPosts property
      if (updatedUserData && updatedUserData.savedPosts) {
        onSavedPostsUpdated(updatedUserData.savedPosts);
      } else {
        // If savedPosts is not available, use the optimistic update
        onSavedPostsUpdated(optimisticSavedPosts);
      }
    } catch (error) {
      console.error('Error toggling save:', error);
      // Revert optimistic update on error
      setIsSaved(!isSaved);
      onSavedPostsUpdated(savedPosts);
    }
  }, [post.id, isSaved, savedPosts, savePost, unsavePost, onSavedPostsUpdated]);

  const formatDate = (dateString) => {
    try {
      if (dateString && dateString.seconds) {
        return format(new Date(dateString.seconds * 1000), 'MMM d, yyyy HH:mm');
      }
      return format(parseISO(dateString), 'MMM d, yyyy HH:mm');
    } catch (error) {
      console.error('Error formatting date:', error);
      return t('common.invalid_date');
    }
  };

  const loadMoreComments = useCallback(() => {
    setCommentsPage(prevPage => prevPage + 1);
  }, []);

  return (
    <div className={styles.chatPostItem}>
      <div className={styles.postHeader} onClick={toggleExpand}>
        <div className={styles.postHeaderContent}>
          <h4>{post.name}</h4>
          <span className={styles.postAuthor}>{t('posts.by')}: {post.authorDisplay}</span>
        </div>
        {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </div>
      {isExpanded && (
        <div className={styles.postContent}>
          <div className={styles.postDescription}>
            <ReactMarkdown>{post.description}</ReactMarkdown>
          </div>
          {post.link && (
            <a href={post.link} target="_blank" rel="noopener noreferrer" className={styles.postLink}>
              {t('posts.view_link')}
            </a>
          )}
          <div className={styles.postMeta}>
            <span>{formatDate(post.createdAt)}</span>
            <span>{t(`posts.privacy.${post.privacy}`)}</span>
          </div>
          <div className={styles.postActions}>
            <Button 
              variant="tertiary" 
              size="small" 
              aria-label={isLiked ? t('posts.unlike_post') : t('posts.like_post')}
              onClick={handleLike}
              className={styles.actionButton}
            >
              <Heart fill={isLiked ? 'var(--color-primary)' : 'none'} />
            </Button>
            <Button 
              variant="tertiary" 
              size="small" 
              aria-label={isCommentSectionVisible ? t('posts.hide_comments') : t('posts.show_comments')}
              className={styles.actionButton}
              onClick={() => onToggleCommentSection(post.id)}
            >
              <MessageSquare />
            </Button>
            <Button 
              variant="tertiary" 
              size="small" 
              aria-label={t('posts.share_post')}
              onClick={handleShare}
              className={styles.actionButton}
            >
              <Share2 />
            </Button>
            <Button 
              variant="tertiary" 
              size="small" 
              aria-label={isSaved ? t('posts.unsave_post') : t('posts.save_post')}
              onClick={handleSave}
              className={styles.actionButton}
            >
              <Bookmark fill={isSaved ? 'var(--color-primary)' : 'none'} />
            </Button>
          </div>
          {shareMessage && <div className={styles.shareMessage}>{shareMessage}</div>}
          {isCommentSectionVisible && (
            <div className={styles.postComments}>
              <h5>{t('comments.title')}</h5>
              {visibleComments.map((comment, index) => (
                <div key={index} className={styles.comment}>
                  <p>
                    <strong>{commenters[comment.userId] || t('common.anonymous')}: </strong>
                    {comment.content}
                  </p>
                  <small>{formatDate(comment.createdAt)}</small>
                </div>
              ))}
              {post.comments.length > visibleComments.length && (
                <Button onClick={loadMoreComments} variant="secondary" size="small">
                  {t('comments.load_more')}
                </Button>
              )}
            </div>
          )}
          {isCommentSectionVisible && (
            <form onSubmit={handleComment} className={styles.commentForm}>
              <input
                type="text"
                value={commentInput}
                onChange={(e) => setCommentInput(e.target.value)}
                placeholder={t('comments.add_comment_placeholder')}
                aria-label={t('comments.add_comment_aria')}
              />
              <Button type="submit" variant="primary" size="small">
                <Send size={16} />
              </Button>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatPostItem;