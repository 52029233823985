// src/hooks/useUserPosts.js
import { useState, useEffect } from 'react';
import { usePost } from './usePost';
import { auth } from '../firebase';
import i18n from 'i18next';

const useUserPosts = (userId) => {
  const [userPosts, setUserPosts] = useState([]);
  const [savedPosts, setSavedPosts] = useState([]);
  const [savedPostIds, setSavedPostIds] = useState([]);
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [postsError, setPostsError] = useState(null);

  const { getUserPosts, getUserSavedPosts } = usePost();

  useEffect(() => {
    const fetchPosts = async () => {
      setLoadingPosts(true);
      const currentUser = auth.currentUser;

      if (!currentUser) {
        setPostsError(i18n.t('errors.user_not_authenticated'));
        setLoadingPosts(false);
        return;
      }

      try {
        const fetchedPosts = await getUserPosts(userId, currentUser.uid);
        setUserPosts(fetchedPosts);

        if (userId === currentUser.uid) {
          const fetchedSavedPosts = await getUserSavedPosts();
          setSavedPosts(fetchedSavedPosts);
          setSavedPostIds(fetchedSavedPosts.map((post) => post.id));
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
        setPostsError(i18n.t('profile.fetch_error'));
      } finally {
        setLoadingPosts(false);
      }
    };

    fetchPosts();
  }, [userId, getUserPosts, getUserSavedPosts]);

  return {
    userPosts,
    savedPosts,
    savedPostIds,
    loadingPosts,
    postsError,
    setUserPosts,
  };
};

export default useUserPosts;