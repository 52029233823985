import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ChatPostItem from './ChatPostItem';
import './ChatPostList.css';

const ChatPostList = ({ postIds, getPostDetails, onPostUpdated, savedPosts, onSavedPostsUpdated }) => {
  const { t } = useTranslation();
  const [visibleCommentSections, setVisibleCommentSections] = useState({});
  const [loadedPosts, setLoadedPosts] = useState({});

  useEffect(() => {
    const loadPosts = async () => {
      if (postIds.length === 0) {
        setLoadedPosts({});
        return;
      }
      const posts = await getPostDetails(postIds);
      const postsObject = posts.reduce((acc, post) => {
        acc[post.id] = post;
        return acc;
      }, {});
      setLoadedPosts(postsObject);
    };
  
    loadPosts();
  }, [postIds, getPostDetails]);

  const handleToggleCommentSection = useCallback((postId) => {
    setVisibleCommentSections(prev => ({
      ...prev,
      [postId]: !prev[postId]
    }));
  }, []);

  const renderedPosts = useMemo(() => {
    return postIds.map(postId => {
      const post = loadedPosts[postId];
      if (!post) return null; // Or a loading placeholder

      return (
        <ChatPostItem 
          key={postId} 
          post={post} 
          onPostUpdated={onPostUpdated}
          savedPosts={savedPosts}
          onSavedPostsUpdated={onSavedPostsUpdated}
          isCommentSectionVisible={visibleCommentSections[postId] || false}
          onToggleCommentSection={handleToggleCommentSection}
        />
      );
    }).filter(Boolean); // Remove null values
  }, [postIds, loadedPosts, onPostUpdated, savedPosts, onSavedPostsUpdated, visibleCommentSections, handleToggleCommentSection]);

  return (
    <div className="chat-post-list">
      <h3 className="search-results-heading">{t('search.results_heading')}</h3>
      {postIds.length > 0 ? (
        <div className="post-list">
          {renderedPosts}
        </div>
      ) : null}
    </div>
  );
};

export default ChatPostList;