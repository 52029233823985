// src/components/GroupChatPostList.js

import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import GroupChatPostItem from './GroupChatPostItem';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import './GroupChatPostList.css';

const GroupChatPostList = ({ postIds }) => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState([]);

  const fetchPosts = useCallback(async () => {
    try {
      // Fetch all posts in parallel
      const postPromises = postIds.map(async (postId) => {
        const postRef = doc(db, 'posts', postId);
        const postSnap = await getDoc(postRef);

        if (postSnap.exists()) {
          const postData = postSnap.data();

          // Fetch the user data for the post's author
          const userRef = doc(db, 'users', postData.userId);
          const userSnap = await getDoc(userRef);

          const authorData = userSnap.exists() ? userSnap.data() : null;

          return {
            id: postSnap.id,
            ...postData,
            author: authorData ? {
              id: userSnap.id,
              username: authorData.username,
              displayName: authorData.displayName,
              profilePictureUrl: authorData.profilePictureUrl
            } : null
          };
        } else {
          // Handle the case where the post doesn't exist
          console.warn(`Post with ID ${postId} does not exist.`);
          return null;
        }
      });

      const fetchedPosts = (await Promise.all(postPromises)).filter(Boolean);
      setPosts(fetchedPosts);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  }, [postIds]);

  useEffect(() => {
    if (postIds && postIds.length > 0) {
      fetchPosts();
    }
  }, [postIds, fetchPosts]);

  const handlePostUpdate = useCallback((updatedPost) => {
    setPosts(currentPosts => 
      currentPosts.map(post => 
        post.id === updatedPost.id ? { ...post, ...updatedPost } : post
      )
    );
  }, []);

  // Check if postIds is empty and return null
  if (!postIds || postIds.length === 0) {
    return null;
  }

  return (
    <div className="group-chat-post-list">
      <h4 className="search-results-heading">{t('search.results_heading')}</h4>
      {posts.map((post) => (
        <GroupChatPostItem 
          key={post.id} 
          post={post} 
          onPostUpdated={handlePostUpdate}
        />
      ))}
    </div>
  );
};

export default GroupChatPostList;
