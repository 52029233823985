import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Home, Users, User } from 'react-feather';
import './BottomNavigation.css';

const BottomNavigation = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="bottom-navigation">
      <Link to="/" aria-label={t('navigation.home')}>
        <Home 
          size={24} 
          fill={isActive('/') ? 'var(--color-primary)' : 'none'} 
          stroke={isActive('/') ? 'var(--color-primary)' : 'currentColor'}
        />
      </Link>
      <Link to="/friends" aria-label={t('navigation.friends')}>
        <Users 
          size={24} 
          fill={isActive('/friends') ? 'var(--color-primary)' : 'none'} 
          stroke={isActive('/friends') ? 'var(--color-primary)' : 'currentColor'}
        />
      </Link>
      <Link to="/profile" aria-label={t('navigation.profile')}>
        <User 
          size={24} 
          fill={isActive('/profile') ? 'var(--color-primary)' : 'none'} 
          stroke={isActive('/profile') ? 'var(--color-primary)' : 'currentColor'}
        />
      </Link>
    </nav>
  );
};

export default BottomNavigation;