// src/components/GroupButton.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { db, auth } from '../firebase';
import { collectionGroup, query, where, onSnapshot } from 'firebase/firestore';
import './GroupButton.css';
import GroupIcon from '../assets/group-icon.png';

const GroupButton = ({ onClick }) => {
  const { t } = useTranslation();
  const [unreadGroupCount, setUnreadGroupCount] = useState(0);

  useEffect(() => {
    if (!auth.currentUser) return;

    // Create a query to find all group memberships for the current user with unreadCount > 0
    const q = query(
      collectionGroup(db, 'members'),
      where('userId', '==', auth.currentUser.uid),
      where('unreadCount', '>', 0)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      // The number of documents corresponds to the number of groups with unread messages
      setUnreadGroupCount(querySnapshot.size);
    }, (error) => {
      console.error("Error fetching unread group count: ", error);
      setUnreadGroupCount(0);
    });

    // Cleanup listener on unmount
    return () => unsubscribe();
  }, []);

  return (
    <button 
      className="group-button"
      onClick={onClick}
      aria-label={t('chat.view_groups')}
    >
      <div className="group-button-container">
        <div className="group-button-icon">
          <img 
            src={GroupIcon} 
            alt={t('chat.groups')}
            className="group-button-img"
          />
        </div>
        {unreadGroupCount > 0 && (
          <div className="group-button-badge">
            {unreadGroupCount}
          </div>
        )}
      </div>
      
      <span className="group-button-text">
        {t('chat.groups')}
      </span>
    </button>
  );
};

export default GroupButton;