// src/components/DefaultAvatar.js
import React from 'react';

const DefaultAvatar = ({ name, size = 100, fallbackColor = 'var(--color-primary-light)', textColor = 'var(--color-surface)' }) => {
  // Color generation function
  const generateColor = (str) => {
    if (!str) return fallbackColor;
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 65%)`;
  };
  // Error handling and fallback for empty or undefined name
  const safeNameProp = name ? name.trim() : '';
  const initials = safeNameProp
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);

  const avatarColor = generateColor(safeNameProp);
  const displayedInitials = initials || '?';

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 150 150"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        borderRadius: '50%',
        overflow: 'hidden',
      }}
    >
      <circle cx="75" cy="75" r="75" fill={avatarColor} />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".35em"
        fontSize="60"
        fontFamily="Arial, sans-serif"
        fill={textColor}
        style={{
          fontWeight: 'bold',
        }}
      >
        {displayedInitials}
      </text>
    </svg>
  );
};

export default DefaultAvatar;