// src/components/AddMembersModal.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { X, Search } from 'lucide-react';
import { auth, db } from '../firebase';
import { 
  collection, 
  query, 
  where, 
  getDoc,
  getDocs,
  writeBatch,
  doc,
  serverTimestamp,
  arrayUnion
} from 'firebase/firestore';
import SelectableFriendItem from './SelectableFriendItem';
import Button from './Button';
import styles from './AddMembersModal.module.css';

const AddMembersModal = ({ group, existingMembers, onClose, onMembersAdded }) => {
  const { t } = useTranslation();
  const [friends, setFriends] = useState([]);
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Load friends who aren't already in the group
  useEffect(() => {
    const fetchAvailableFriends = async () => {
      try {
        const userId = auth.currentUser.uid;
        const userDoc = await getDoc(doc(db, 'users', userId));
        const userData = userDoc.data();
        const friendIds = userData.friends || [];

        // Filter out existing members
        const existingMemberIds = existingMembers.map(member => member.userId);
        const availableFriendIds = friendIds.filter(
          id => !existingMemberIds.includes(id)
        );

        if (availableFriendIds.length === 0) {
          return;
        }

        // Process in chunks of 10 due to Firestore limitation
        const chunkSize = 10;
        const friendIdChunks = [];
        for (let i = 0; i < availableFriendIds.length; i += chunkSize) {
          friendIdChunks.push(availableFriendIds.slice(i, i + chunkSize));
        }

        let friendsList = [];
        for (const chunk of friendIdChunks) {
          const friendsQuery = query(
            collection(db, 'users'),
            where('__name__', 'in', chunk)
          );
          const friendsSnapshot = await getDocs(friendsQuery);
          const chunkFriends = friendsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          friendsList = friendsList.concat(chunkFriends);
        }

        setFriends(friendsList);
      } catch (error) {
        console.error('Error fetching friends:', error);
        setError(t('groups.friends_fetch_error'));
      }
    };

    fetchAvailableFriends();
  }, [existingMembers, t]);

  const toggleFriendSelection = (friendId) => {
    setSelectedFriends(prev => {
      if (prev.includes(friendId)) {
        return prev.filter(id => id !== friendId);
      }
      return [...prev, friendId];
    });
  };

  const filteredFriends = friends.filter(friend => {
    const searchTerm = searchQuery.toLowerCase();
    const displayName = (friend.displayName || '').toLowerCase();
    const username = (friend.username || '').toLowerCase();
    return displayName.includes(searchTerm) || username.includes(searchTerm);
  });

  const handleAddMembers = async () => {
    if (selectedFriends.length === 0) {
      setError(t('groups.select_members_error'));
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const batch = writeBatch(db);
      
      // Add each selected friend as a member
      selectedFriends.forEach(friendId => {
        const memberRef = doc(db, `groups/${group.id}/members/${friendId}`);
        batch.set(memberRef, {
          userId: friendId,
          joinedAt: serverTimestamp(),
          lastReadAt: serverTimestamp(),
          role: 'member',
          notificationsEnabled: true,
        });
      });

      // Update group member count and members array
      const groupRef = doc(db, 'groups', group.id);
      batch.update(groupRef, {
        memberCount: group.memberCount + selectedFriends.length,
        members: arrayUnion(...selectedFriends),
        lastActivityAt: serverTimestamp()
      });

      await batch.commit();

      // Get the user data for added members
      const addedMembers = friends
        .filter(friend => selectedFriends.includes(friend.id))
        .map(friend => ({
          id: friend.id,
          userId: friend.id,
          role: 'member',
          joinedAt: new Date(),
          lastReadAt: new Date(),
          displayName: friend.displayName || t('common.anonymous'),
          profilePicture: friend.profilePicture
        }));

      onMembersAdded(addedMembers);
      onClose();
    } catch (error) {
      console.error('Error adding members:', error);
      setError(t('groups.add_members_error'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        {/* Header */}
        <div className={styles.modalHeader}>
          <button 
            onClick={onClose}
            className={styles.closeButton}
            aria-label={t('common.close')}
          >
            <X size={24} />
          </button>
          <h2 className={styles.title}>{t('groups.add_members')}</h2>
          <Button
            onClick={handleAddMembers}
            disabled={isLoading || selectedFriends.length === 0}
            variant="primary"
            size="small"
          >
            {isLoading ? t('groups.adding_members') : t('common.add')}
          </Button>
        </div>

        {/* Search Bar */}
        <div className={styles.searchContainer}>
          <div className={styles.searchWrapper}>
            <Search size={18} className={styles.searchIcon} />
            <input
              type="text"
              placeholder={t('groups.search_friends')}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={styles.searchInput}
            />
          </div>
        </div>

        {/* Friends List */}
        <div className={styles.friendsList}>
          {filteredFriends.length > 0 ? (
            filteredFriends.map(friend => (
              <SelectableFriendItem
                key={friend.id}
                friend={friend}
                isSelected={selectedFriends.includes(friend.id)}
                onToggleSelect={toggleFriendSelection}
                disabled={isLoading}
              />
            ))
          ) : (
            <p className={styles.noFriendsMessage}>
              {searchQuery 
                ? t('groups.no_search_results')
                : t('groups.no_available_friends')}
            </p>
          )}
        </div>

        {error && <div className={styles.errorMessage}>{error}</div>}

        {/* Loading Overlay */}
        {isLoading && (
          <div className={styles.loadingOverlay}>
            <div className={styles.spinner} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddMembersModal;