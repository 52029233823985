import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { auth, db } from '../firebase';
import { doc, getDoc, updateDoc, arrayRemove, arrayUnion } from 'firebase/firestore';
import Button from './Button';

const FriendRequests = () => {
  const { t } = useTranslation();
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const fetchRequests = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        const requestIds = userDoc.data().friendRequests || [];
        
        const requestsData = await Promise.all(
          requestIds.map(async (requestId) => {
            const requestDoc = await getDoc(doc(db, 'users', requestId));
            return { id: requestId, ...requestDoc.data() };
          })
        );

        setRequests(requestsData);
      }
    };

    fetchRequests();
  }, []);

  const handleRequest = async (requestId, accept) => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const userRef = doc(db, 'users', currentUser.uid);
      const friendRef = doc(db, 'users', requestId);

      await updateDoc(userRef, {
        friendRequests: arrayRemove(requestId)
      });

      if (accept) {
        await updateDoc(userRef, {
          friends: arrayUnion(requestId)
        });
        await updateDoc(friendRef, {
          friends: arrayUnion(currentUser.uid)
        });
      }

      setRequests(requests.filter(request => request.id !== requestId));
    }
  };

  return (
    <div className="friend-requests">
      <h3>{t('friends.friend_requests')}</h3>
      {requests.length === 0 ? (
        <p>{t('friends.no_friend_requests')}</p>
      ) : (
        <ul>
          {requests.map((request) => (
            <li key={request.id} className="friend-request-item">
              <span className="friend-name">{request.displayName || t('common.anonymous')}</span>
              <div className="friend-request-actions">
                <Button 
                  onClick={() => handleRequest(request.id, true)} 
                  variant="primary" 
                  size="small"
                >
                  {t('friends.accept')}
                </Button>
                <Button 
                  onClick={() => handleRequest(request.id, false)} 
                  variant="secondary" 
                  size="small"
                >
                  {t('friends.decline')}
                </Button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FriendRequests;