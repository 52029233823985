// src/components/GroupCreateModal.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { X, Camera, Search } from 'lucide-react';
import { auth, db, storage } from '../firebase';
import { 
  collection, 
  query, 
  where, 
  getDoc,
  getDocs,
  updateDoc,
  serverTimestamp,
  doc,
  writeBatch
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import SelectableFriendItem from './SelectableFriendItem';
import DefaultAvatar from './DefaultAvatar';
import styles from './GroupCreateModal.module.css';

const GroupCreateModal = ({ onClose, onGroupCreated }) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [iconFile, setIconFile] = useState(null);
  const [iconPreview, setIconPreview] = useState(null);
  const [friends, setFriends] = useState([]);
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchFriends = async () => {
      try {
        const userId = auth.currentUser.uid;
        const userDoc = await getDoc(doc(db, 'users', userId));
        const userData = userDoc.data();
        const friendIds = userData.friends || [];

        if (friendIds.length === 0) {
          return;
        }

        // Firestore 'in' queries have a maximum of 10 elements
        const chunkSize = 10;
        const friendIdChunks = [];
        for (let i = 0; i < friendIds.length; i += chunkSize) {
          friendIdChunks.push(friendIds.slice(i, i + chunkSize));
        }

        let friendsList = [];
        for (const chunk of friendIdChunks) {
          const friendsQuery = query(
            collection(db, 'users'),
            where('__name__', 'in', chunk)
          );
          const friendsSnapshot = await getDocs(friendsQuery);
          const chunkFriends = friendsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          friendsList = friendsList.concat(chunkFriends);
        }

        setFriends(friendsList);
      } catch (error) {
        console.error('Error fetching friends:', error);
        setError(t('groups.friends_fetch_error'));
      }
    };

    fetchFriends();
  }, [t]);

  useEffect(() => {
    if (iconFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setIconPreview(e.target.result);
      };
      reader.readAsDataURL(iconFile);
    }
  }, [iconFile]);

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setIconFile(file);
    }
  };

  const toggleFriendSelection = (friendId) => {
    setSelectedFriends(prev => {
      if (prev.includes(friendId)) {
        return prev.filter(id => id !== friendId);
      }
      return [...prev, friendId];
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedFriends.length === 0) {
      setError(t('groups.select_members_error'));
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const userId = auth.currentUser.uid;

      // Include the creator's UID and selected friends' UIDs in the members array
      const allMemberIds = [userId, ...selectedFriends];

      // Define group data
      const groupData = {
        name: name.trim() || generateDefaultName(),
        icon: null, // Temporarily set icon to null; will update after upload
        createdBy: userId,
        createdAt: serverTimestamp(),
        lastActivityAt: serverTimestamp(),
        memberCount: allMemberIds.length,
        members: allMemberIds,
      };

      // Initialize a new batch
      const batch = writeBatch(db);

      // Create a new group reference with a unique ID
      const groupRef = doc(collection(db, 'groups'));
      
      // Add the group document to the batch
      batch.set(groupRef, groupData);

      // Add the creator as a member
      const creatorMemberRef = doc(db, `groups/${groupRef.id}/members`, userId);
      batch.set(creatorMemberRef, {
        userId: userId,
        joinedAt: serverTimestamp(),
        lastReadAt: serverTimestamp(),
        role: 'admin',
        notificationsEnabled: true,
      });

      // Add selected friends as members
      selectedFriends.forEach(friendId => {
        const memberRef = doc(db, `groups/${groupRef.id}/members`, friendId);
        batch.set(memberRef, {
          userId: friendId,
          joinedAt: serverTimestamp(),
          lastReadAt: serverTimestamp(),
          role: 'member',
          notificationsEnabled: true,
        });
      });

      // Commit the batch
      await batch.commit();

      // Upload the icon to the correct path using groupId
      let iconUrl = null;
      if (iconFile) {
        const iconRef = ref(storage, `group-icons/${groupRef.id}/${Date.now()}-${iconFile.name}`);
        await uploadBytes(iconRef, iconFile);
        iconUrl = await getDownloadURL(iconRef);

        // Update the group document with the icon URL
        await updateDoc(groupRef, { icon: iconUrl });
      }

      // Update the user's groupIds array in their user document (optional)
      // const userRef = doc(db, 'users', userId);
      // await updateDoc(userRef, {
      //   groupIds: arrayUnion(groupRef.id),
      // });

      // Notify the parent component about the new group
      onGroupCreated({
        id: groupRef.id,
        ...groupData,
        icon: iconUrl
      });
      
    } catch (error) {
      console.error('Error creating group:', error);
      setError(t('groups.create_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const generateDefaultName = () => {
    const selectedFriendNames = friends
      .filter(friend => selectedFriends.includes(friend.id))
      .map(friend => friend.displayName || friend.username || t('common.anonymous'))
      .slice(0, 3);
    
    return selectedFriendNames.join(', ');
  };

  const filteredFriends = friends.filter(friend => {
    const searchTerm = searchQuery.toLowerCase();
    const displayName = (friend.displayName || '').toLowerCase();
    const username = (friend.username || '').toLowerCase();
    return displayName.includes(searchTerm) || username.includes(searchTerm);
  });

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        {/* Header */}
        <div className={styles.modalHeader}>
          <button onClick={onClose} className={styles.closeButton}>
            <X size={24} />
          </button>
          <h2>{t('groups.create_group')}</h2>
          <button
            onClick={handleSubmit}
            disabled={!selectedFriends.length || isLoading}
            className={styles.createButton}
          >
            {isLoading ? t('common.creating') : t('groups.create')}
          </button>
        </div>

        {/* Group Info Section */}
        <div className={styles.groupInfo}>
          <div className={styles.iconUploadContainer}>
            <input
              type="file"
              id="group-icon"
              accept="image/*"
              onChange={handleIconChange}
              className={styles.hiddenInput}
              disabled={isLoading}
            />
            <label htmlFor="group-icon" className={styles.iconLabel}>
              {iconPreview ? (
                <img
                  src={iconPreview}
                  alt={t('groups.group_icon')}
                  className={styles.iconPreview}
                />
              ) : (
                <Camera size={24} className={styles.cameraIcon} />
              )}
            </label>
          </div>
          <input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder={t('groups.group_name_placeholder')}
            className={styles.groupNameInput}
            disabled={isLoading}
          />
        </div>

        {/* Search Section */}
        <div className={styles.searchContainer}>
          <div className={styles.searchWrapper}>
            <Search size={20} className={styles.searchIcon} />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t('groups.search_friends')}
              className={styles.searchInput}
            />
          </div>
        </div>

        {/* Selected Friends Preview */}
        {selectedFriends.length > 0 && (
          <div className={styles.selectedFriends}>
            {selectedFriends.map(friendId => {
              const friend = friends.find(f => f.id === friendId);
              if (!friend) return null;
              
              return (
                <div key={friendId} className={styles.selectedFriendItem}>
                  <div className={styles.selectedFriendImageContainer}>
                    {friend.profilePictureUrl ? (
                      <img
                        src={friend.profilePictureUrl}
                        alt={friend.displayName || friend.username}
                        className={styles.selectedFriendImage}
                      />
                    ) : (
                      <DefaultAvatar
                        name={(friend.displayName || friend.username || t('common.anonymous'))}
                        size={48}
                        fallbackColor="var(--color-primary-light)"
                        textColor="var(--color-surface)"
                      />
                    )}
                    <button
                      onClick={() => toggleFriendSelection(friendId)}
                      className={styles.removeButton}
                    >
                      <X size={14} />
                    </button>
                  </div>
                  <span className={styles.selectedFriendName}>
                    {friend.displayName || friend.username || t('common.anonymous')}
                  </span>
                </div>
              );
            })}
          </div>
        )}

        {/* Friends List */}
        <div className={styles.friendsList}>
          {filteredFriends.length > 0 ? (
            filteredFriends.map(friend => (
              <SelectableFriendItem
                key={friend.id}
                friend={friend}
                isSelected={selectedFriends.includes(friend.id)}
                onToggleSelect={toggleFriendSelection}
                disabled={isLoading}
              />
            ))
          ) : (
            <p className={styles.noFriendsMessage}>
              {searchQuery ? t('groups.no_search_results') : t('groups.no_friends')}
            </p>
          )}
        </div>

        {error && <div className={styles.errorMessage}>{error}</div>}
      </div>
    </div>
  );
};

export default GroupCreateModal;