// src/components/ProfileForm.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { auth, db, storage } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Button from './Button';
import ProfileImageUpload from './ProfileImageUpload';
import { validateUsername } from '../utils/validation';
import './ProfileForm.css';

const ProfileForm = ({ existingProfile, onProfileUpdate, onCancel }) => {
  const { t } = useTranslation();
  const [displayName, setDisplayName] = useState(existingProfile?.displayName || '');
  const [username, setUsername] = useState(existingProfile?.username || '');
  const [originalUsername, setOriginalUsername] = useState(existingProfile?.username || '');
  const [bio, setBio] = useState(existingProfile?.bio || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState({});
  const [touched, setTouched] = useState({});
  const [profilePicture, setProfilePicture] = useState(null);

  useEffect(() => {
    if (existingProfile) {
      setDisplayName(existingProfile.displayName || '');
      setUsername(existingProfile.username || '');
      setOriginalUsername(existingProfile.username || '');
      setBio(existingProfile.bio || '');
    }
  }, [existingProfile]);

  const handleUsernameChange = (e) => {
    const lowercaseInput = e.target.value.toLowerCase();
    setUsername(lowercaseInput);
    setTouched({ ...touched, username: true });
  };

  const handleImageSelect = (file) => {
    setProfilePicture(file);
  };

  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
  };

  const validateForm = () => {
    const errors = {};
    if (!displayName.trim()) errors.displayName = 'profile.display_name_required';
    const usernameError = validateUsername(username);
    if (usernameError) errors.username = usernameError; // e.g., 'validation.username.invalid'
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setError(formErrors);
      return;
    }

    setIsSubmitting(true);
    setError({});

    // Get the currently authenticated user
    const user = auth.currentUser;
    if (!user) {
      setError({ general: 'errors.user_not_authenticated' });
      setIsSubmitting(false);
      return;
    }

    // Normalize the phone number if it exists
    let normalizedPhoneNumber = null;
    if (user.phoneNumber) {
      try {
        normalizedPhoneNumber = '+' + user.phoneNumber.replace(/\s+/g, '').replace(/^\+/, '');
      } catch (phoneError) {
        console.warn("Invalid phone number format:", user.phoneNumber);
        // Optionally, you can set an error or handle it as needed
      }
    }

    try {
      let updatedUsername = originalUsername;

      // Update username if it has changed
      if (username !== originalUsername) {
        const functions = getFunctions();
        const setUsernameFunction = httpsCallable(functions, 'setUsername');
        const result = await setUsernameFunction({ username });

        if (!result.data.success) {
          throw new Error('profile.username_set_failed');
        }
        updatedUsername = username;
      }

      // Initialize profilePictureUrl
      let profilePictureUrl = existingProfile?.profilePictureUrl || '';

      // Check if a new profile picture has been selected
      if (profilePicture) {
        try {
          // Compress the image to optimize size
          const compressedFile = await imageCompression(profilePicture, {
            maxSizeMB: 1,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
          });

          // Create a reference to Firebase Storage
          const storageRef = ref(storage, `profilePictures/${user.uid}/profile.jpg`);

          // Upload the compressed image to Firebase Storage
          await uploadBytes(storageRef, compressedFile);

          // Retrieve the download URL for the uploaded image
          profilePictureUrl = await getDownloadURL(storageRef);
        } catch (uploadError) {
          console.error("Error uploading profile picture: ", uploadError);
          setError({ general: 'profile.profile_picture_upload_failed' });
          setIsSubmitting(false);
          return;
        }
      }

      // Prepare the profile data object
      const profileData = {
        displayName,
        username: updatedUsername,
        bio,
        // Include phoneNumber only if it exists
        ...(normalizedPhoneNumber && { phoneNumber: normalizedPhoneNumber }),
        profilePictureUrl,
        updatedAt: new Date(),
      };

      // Update the user's document in Firestore
      await setDoc(doc(db, 'users', user.uid), profileData, { merge: true });
      setOriginalUsername(updatedUsername);

      // Update the profile in the parent component
      onProfileUpdate(profileData);
    } catch (error) {
      console.error("Error updating profile: ", error);
      setError({ general: error.message || 'profile.update_failed' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="profile-form">
      <ProfileImageUpload
        initialImage={existingProfile?.profilePictureUrl}
        onImageSelect={handleImageSelect}
      />
      {/* Display Name Field */}
      <div className="form-group">
        <label htmlFor="displayName">{t('profile.display_name')}</label>
        <input
          id="displayName"
          type="text"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          onBlur={() => handleBlur('displayName')}
          placeholder={t('profile.enter_display_name')}
          required
          aria-required="true"
          aria-invalid={touched.displayName && !displayName.trim()}
        />
        {/* Inline Validation Error */}
        {touched.displayName && !displayName.trim() && (
          <p className="error-message">{t('profile.display_name_required')}</p>
        )}
        {/* Form Submission Error */}
        {error && error.displayName && (
          <p className="error-message">{t(error.displayName)}</p>
        )}        
      </div>
      {/* Username Field */}
      <div className="form-group">
        <label htmlFor="username">{t('profile.username')}</label>
        <input
          id="username"
          type="text"
          value={username}
          onChange={handleUsernameChange}
          onBlur={() => handleBlur('username')}
          placeholder={t('profile.enter_username')}
          required
          aria-required="true"
          aria-invalid={touched.username && !!validateUsername(username)}
        />
        {/* Inline Validation Error */}
        {touched.username && validateUsername(username) && (
          <p className="error-message">{t(validateUsername(username))}</p>
        )}
        {/* Form Submission Error */}
        {error && error.username && (
          <p className="error-message">{t(error.username)}</p>
        )}        
      </div>
      {/* Bio Field */}
      <div className="form-group">
        <label htmlFor="bio">{t('profile.bio')}</label>
        <textarea
          id="bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          placeholder={t('profile.enter_bio')}
          aria-label={t('profile.bio')}
        />
      </div>
      {/* General Error Message */}
      {error && error.general && (
        <p className="error-message" role="alert">{t(error.general)}</p>
      )}
      <div className="profile-form-actions">
        <Button 
          type="button" 
          variant="secondary" 
          size="medium" 
          onClick={onCancel}
          disabled={isSubmitting}
        >
          {t('common.cancel')}
        </Button>
        <Button 
          type="submit" 
          variant="primary" 
          size="medium" 
          disabled={isSubmitting}
        >
          {isSubmitting ? t('profile.saving') : t('profile.save_profile')}
        </Button>
      </div>
    </form>
  );
};

export default ProfileForm;