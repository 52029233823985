// src/components/ChatInterface.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { auth, db } from '../firebase';
import { 
  collection, 
  addDoc, 
  query, 
  orderBy, 
  limit, 
  onSnapshot,
  where,
  getDocs
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Send, Loader, Image, X } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { Link, useNavigate } from 'react-router-dom';
import Button from './Button';
import GroupButton from './GroupButton';
import { getSessionInfo, manualRefresh, setConversationId, refreshToken } from '../authManager';
import ChatPostList from './ChatPostList';
import PostPreview from './PostPreview';
import { getUserSavedPosts, createPost } from '../services/postService';
import './ChatInterface.css';
import icon from '../assets/icon.png';
import { useChat } from '../contexts/ChatContext';
import ImageUploader from "./ImageUploader";

const MAX_IMAGES = 6; // t('chat.max_images_per_message')

const ChatInterface = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { chatState, setChatState } = useChat();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sessionInfo, setSessionInfo] = useState(null);
  const [savedPosts, setSavedPosts] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [showSendButton, setShowSendButton] = useState(false);
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const functions = getFunctions();
  const difyChat = httpsCallable(functions, 'difyChat');

  useEffect(() => {
    const fetchSessionInfo = async () => {
      const info = await getSessionInfo();
      setSessionInfo(info);
    };

    fetchSessionInfo();
    const sessionCheckInterval = setInterval(fetchSessionInfo, 60000);

    return () => clearInterval(sessionCheckInterval);
  }, []);

  useEffect(() => {
    const getLocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
          },
          (error) => {
            console.error("Error obtaining location:", error);
            getFallbackLocation();
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        getFallbackLocation();
      }
    };
  
    getLocation();
  }, []);

  const getFallbackLocation = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.latitude && data.longitude) {
        setUserLocation({
          latitude: data.latitude,
          longitude: data.longitude
        });
      }
    } catch (error) {
      console.error("Error getting fallback location:", error);
      setUserLocation(null);
    }
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const q = query(
        collection(db, `users/${user.uid}/messages`),
        orderBy('timestamp', 'desc'),
        limit(50)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const fetchedMessages = [];
        querySnapshot.forEach((doc) => {
          fetchedMessages.push({ id: doc.id, ...doc.data() });
        });
        setMessages(fetchedMessages.reverse());
      });

      // Fetch saved posts
      fetchSavedPosts();

      return () => unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages.length]);

  useEffect(() => {
    if (chatState.draftPost) {
      setMessages(prevMessages => {
        const existingPreviewIndex = prevMessages.findIndex(
          msg => msg.type === 'post_preview' && msg.post.id === chatState.draftPost.id
        );
        
        if (existingPreviewIndex === -1) {
          return [...prevMessages, {
            id: Date.now(),
            type: 'post_preview',
            post: chatState.draftPost,
            sender: 'bot',
            timestamp: new Date()
          }];
        }
        
        return prevMessages;
      });
    }
  }, [chatState.draftPost]);

  // New effect for auto-expanding textarea
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '48px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  const fetchSavedPosts = async () => {
    try {
      const savedPostsData = await getUserSavedPosts();
      setSavedPosts(savedPostsData.map(post => post.id));
    } catch (error) {
      console.error('Error fetching saved posts:', error);
    }
  };

  const userInfoCache = new Map();

  const fetchPostDetails = async (postIds) => {
    // Guard clause to handle empty postIds array
    if (postIds.length === 0) return [];

    try {
      const postsRef = collection(db, 'posts');
      const q = query(postsRef, where('__name__', 'in', postIds));
      const querySnapshot = await getDocs(q);
      
      const posts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
  
      const userIds = [...new Set(posts.map(post => post.userId))];
      const uncachedUserIds = userIds.filter(id => !userInfoCache.has(id));
  
      if (uncachedUserIds.length > 0) {
        const usersRef = collection(db, 'users');
        const userQuery = query(usersRef, where('__name__', 'in', uncachedUserIds));
        const userSnapshot = await getDocs(userQuery);
  
        userSnapshot.forEach(doc => {
          const userData = doc.data();
          userInfoCache.set(doc.id, {
            displayName: userData.displayName || t('common.anonymous'),
            username: userData.username || ''
          });
        });
      }
  
      const postsWithAuthors = posts.map(post => {
        const userInfo = userInfoCache.get(post.userId) || { displayName: t('common.anonymous'), username: '' };
        const authorDisplay = userInfo.username 
          ? `${userInfo.displayName} (@${userInfo.username})`
          : userInfo.displayName;
        return {
          ...post,
          authorDisplay
        };
      });
  
      return postsWithAuthors;
    } catch (error) {
      console.error('Error fetching post details:', error);
      return [];
    }
  };

  const handleImageUpload = (imagesData) => {
    setUploadedImages((prevImages) => [...prevImages, ...imagesData]);
  };

  const handleRemoveImage = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleImageIconClick = () => {
    fileInputRef.current.click();
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if ((input.trim() === '' && uploadedImages.length === 0) || !sessionInfo) return;
  
    const user = auth.currentUser;
    if (!user) return;
  
    setIsLoading(true);
    setError(null);
  
    try {
      // Prepare the images array
      const images = uploadedImages.map((image) => ({
        url: image.url,
        name: image.name,
        type: image.type,
      }));
      
      // Add user message to messages
      const userMessage = {
        id: Date.now(),
        text: input,
        sender: 'user',
        timestamp: new Date(),
        images: images.length > 0 ? images : null,
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
  
      await addDoc(collection(db, `users/${user.uid}/messages`), userMessage);
  
      let latestSessionInfo = await getSessionInfo();
      
      if (!latestSessionInfo.conversationId) {
        await refreshToken();
        latestSessionInfo = await getSessionInfo(true);
      }
  
      setChatState((prevState) => ({
        ...prevState,
        pendingRequests: [...prevState.pendingRequests, input],
      }));

      const result = await difyChat({
        query: input,
        conversationId: latestSessionInfo.conversationId,
        user_firebase_token: latestSessionInfo.token,
        coordinates: userLocation,
        images: images, // Pass the array of images
      });
      
      console.log("Dify Chat Result:", result.data);
  
      const { botResponse, conversationId: newConversationId } = result.data;
  
      if (botResponse && botResponse.trim() !== "") {
        let parsedResponse;
        try {
          parsedResponse = JSON.parse(botResponse);
        } catch (error) {
          console.log("Bot response is not JSON, treating as plain text");
          parsedResponse = { message: botResponse };
        }
  
        // Always add the bot's message to the chat
        const botMessage = {
          id: Date.now(),
          text: parsedResponse.message || parsedResponse.result?.message || t('chat.default_bot_response'),
          sender: 'bot',
          timestamp: new Date(),
        };
        setMessages(prevMessages => [...prevMessages, botMessage]);
  
        // Save the bot's message to Firebase
        await addDoc(collection(db, `users/${user.uid}/messages`), botMessage);
  
        // If there's a post, add it as a separate preview message
        if (parsedResponse.post) {
          setChatState(prevState => ({
            ...prevState,
            draftPost: parsedResponse.post,
            pendingRequests: prevState.pendingRequests.filter(req => req !== input),
            responses: [...prevState.responses, parsedResponse],
          }));
          
          const postPreviewMessage = {
            id: Date.now() + 1,
            sender: 'bot',
            timestamp: new Date(),
            type: 'post_preview',
            post: parsedResponse.post,
          };
          setMessages(prevMessages => [...prevMessages, postPreviewMessage]);
        } else if (parsedResponse.result && parsedResponse.result.posts) {
          const postIds = parsedResponse.result.posts.map(post => post.id);
          
          const searchResultMessage = {
            id: Date.now() + 2,
            sender: 'bot',
            timestamp: new Date(),
            type: 'search_results',
            postIds: postIds,
          };
          setMessages(prevMessages => [...prevMessages, searchResultMessage]);

          // Save search results to Firebase
          await addDoc(collection(db, `users/${user.uid}/messages`), {
            text: postIds.length > 0 ? "Here are the search results:" : "No posts found.",
            sender: 'bot',
            timestamp: new Date(),
            type: 'search_results',
            postIds: postIds,
          });

          setChatState(prevState => ({
            ...prevState,
            pendingRequests: prevState.pendingRequests.filter(req => req !== input),
            responses: [...prevState.responses, parsedResponse],
          }));
        }
  
        if (newConversationId && newConversationId !== latestSessionInfo.conversationId) {
          await setConversationId(newConversationId);
          setSessionInfo({ ...latestSessionInfo, conversationId: newConversationId });
        }
      } else {
        console.error('No bot response received or response is empty');
        setError(t('chat.no_bot_response'));
      }
      setInput('');
      setUploadedImages([]); // Clear all uploaded images
      setShowSendButton(false);  // Hide the send button after sending
    } catch (error) {
      console.error('Error sending message:', error);
      setError(t('chat.send_error'));
      // Attempt to refresh the session on error
      await handleManualRefresh();
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreatePost = async (post) => {
    setIsLoading(true);
    setError(null);

    try {
      if (!post.name || !post.description) {
        throw new Error(t('posts.name_description_required'));
      }
      
      const result = await createPost(
        post.name,
        post.description,
        post.link || '',
        post.privacy || 'public',
        post.searchMetadata || []
      );
      
      setMessages(prevMessages => [...prevMessages, 
        { id: Date.now(), sender: 'bot', text: t('posts.create_success', { name: result.name }) }
      ]);
      setChatState(prevState => ({
        ...prevState,
        draftPost: null,
      }));
    } catch (error) {
      console.error('Error creating post:', error);
      setError(error.message || t('posts.create_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleManualRefresh = async () => {
    try {
      await manualRefresh();
      const info = await getSessionInfo(true);  // Force a refresh
      setSessionInfo(info);
      console.log('Session manually refreshed');
      setMessages([]); // Clear the chat history
      setError(null);
      setChatState(prevState => ({
        ...prevState,
        draftPost: null,
        pendingRequests: [],
        responses: [],
      }));
    } catch (error) {
      console.error('Error during manual refresh:', error);
      setError(t('errors.session_refresh_failed'));
    }
  };

  const handlePostUpdate = useCallback((updatedPost) => {
    setMessages(prevMessages => prevMessages.map(message => {
      if (message.type === 'search_results') {
        return {
          ...message,
          postIds: message.postIds.map(id => 
            id === updatedPost.id ? updatedPost.id : id
          )
        };
      }
      return message;
    }));
  }, []);

  const handleSavedPostsUpdate = (updatedSavedPosts) => {
    setSavedPosts(updatedSavedPosts);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInput(inputValue);
    setShowSendButton(inputValue.trim().length > 0 || uploadedImages.length > 0);
  };

  const MarkdownLink = ({ href, children }) => {
    const isExternal = href.startsWith('http');
    return isExternal ? (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ) : (
      <Link to={href}>{children}</Link>
    );
  };

  const MessageContent = ({ message }) => {
    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
      setImageError(true);
    };

    return (
      <>
        {message.images && message.images.map((image, idx) => (
          image.deletedAt ? (
            <div key={idx} className="deleted-image-placeholder">
              {t('chat.image_deleted', { date: new Date(image.deletedAt.toDate()).toLocaleDateString() })}
            </div>
          ) : image.url && !imageError ? (
            <img 
              key={idx}
              src={image.url}
              alt={image.name || t('chat.uploaded_image', { index: idx + 1 })}
              className="message-image"
              onError={handleImageError}
            />
          ) : (
            <div key={idx} className="image-error-placeholder">
              {t('chat.image_load_error')}
            </div>
          )
        ))}
        <ReactMarkdown components={{ a: MarkdownLink }}>
          {message.text}
        </ReactMarkdown>
      </>
    );
  };

  return (
    <div className="chat-interface">
      <div className="chat-header">
        <div className="left-section">
          <img src={icon} alt={t('chat.affin_icon')} className="affin-icon" />
          <h2>{t('app_name')}</h2>
        </div>
        <GroupButton onClick={() => navigate('/groups')} />
      </div>
      {chatState.draftPost && (
        <div className="draft-post-indicator">
          {t('chat.draft_post_prompt')}
          <Button onClick={() => {
            const existingPreview = messages.find(
              msg => msg.type === 'post_preview' && msg.post.id === chatState.draftPost.id
            );
            
            if (!existingPreview) {
              setMessages(prevMessages => [...prevMessages, { 
                id: Date.now(),
                type: 'post_preview',
                post: chatState.draftPost,
                sender: 'bot',
                timestamp: new Date()
              }]);
            }
          }}>
            {t('chat.continue_editing')}
          </Button>
        </div>
      )}
      <div className="message-list">
        {messages.map((message) => (
          <div key={message.id} className={`message-container ${message.sender}`}>
            {message.type === 'search_results' ? (
              message.postIds.length > 0 ? (
                <div className="search-results">
                  <ChatPostList 
                    postIds={message.postIds}
                    getPostDetails={fetchPostDetails}
                    onPostUpdated={handlePostUpdate}
                    savedPosts={savedPosts}
                    onSavedPostsUpdated={handleSavedPostsUpdate}
                  />
                </div>
              ) : null
            ) : message.type === 'post_preview' ? (
              <div className="post-preview-container">
                <PostPreview 
                  post={message.post} 
                  onPostCreated={handleCreatePost}
                  onClose={() => {
                    setMessages(prevMessages => 
                      prevMessages.filter(msg => msg.id !== message.id)
                    );
                    setChatState(prevState => ({
                      ...prevState,
                      draftPost: null,
                    }));
                  }}
                />
              </div>
            ) : (
              <div className={`message ${message.sender}`}>
                <MessageContent message={message} />
              </div>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="message-input-container">
        {uploadedImages.length > 0 && (
          <div className="uploaded-images-preview">
            {uploadedImages.map((image, index) => (
              <div key={index} className="uploaded-image-container">
                <img src={image.url} alt={t('chat.uploaded_image', { index: index + 1 })} className="uploaded-image" />
                <button onClick={() => handleRemoveImage(index)} className="remove-image-button" aria-label={t('chat.remove_image')}>
                  <X size={20} />
                </button>
              </div>
            ))}
          </div>
        )}
        <form onSubmit={sendMessage} className="message-input">
          <button
            type="button"
            onClick={handleImageIconClick}
            className="image-upload-button"
            aria-label={t('chat.upload_images')}
            disabled={uploadedImages.length >= MAX_IMAGES || isLoading}
            title={uploadedImages.length >= MAX_IMAGES ? t('chat.max_images_reached', { max: MAX_IMAGES }) : t('chat.upload_images')}
          >
            <Image size={22} />
          </button>
          <textarea
            ref={textareaRef}
            value={input}
            onChange={handleInputChange}
            placeholder={t('chat.type_message')}
            disabled={isLoading}
            rows={1}
          />
          <button
            type="submit"
            disabled={isLoading || (input.trim().length === 0 && uploadedImages.length === 0)}
            className={`send-button ${showSendButton ? 'visible' : ''} ${isLoading ? 'loading' : ''}`}
            aria-label={isLoading ? t('chat.processing_message') : t('chat.send_message')}
          >
            {isLoading ? <Loader size={20} className="loader" /> : <Send size={20} />}
          </button>
        </form>
      </div>
      <ImageUploader
        onImageUpload={handleImageUpload}
        fileInputRef={fileInputRef}
        currentImageCount={uploadedImages.length}
        maxImages={MAX_IMAGES}
      />
    </div>
  );
};

export default ChatInterface;