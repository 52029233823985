// src/components/NotificationBanner.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './NotificationBanner.css';

const NotificationBanner = ({ notification, onDismiss }) => {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onDismiss();
    }, 5000); // Auto-dismiss after 5 seconds

    return () => clearTimeout(timer);
  }, [onDismiss]);

  const handleClick = () => {
    if (notification.type === 'group_message' && notification.groupId) {
      navigate(`/group/${notification.groupId}`);
    } else if (notification.relatedId) {
      navigate(`/post/${notification.relatedId}`);
    }
    onDismiss();
  };

  if (!isVisible) return null;

  return (
    <div className="notification-banner" onClick={handleClick}>
      <p className="notification-title">{notification.title}</p>
      <p className="notification-content">{notification.body}</p>
    </div>
  );
};

export default NotificationBanner;