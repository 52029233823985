// src/components/SelectableFriendItem.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from 'lucide-react';
import DefaultAvatar from './DefaultAvatar';
import styles from './SelectableFriendItem.module.css';

const SelectableFriendItem = ({ friend, isSelected, onToggleSelect, disabled }) => {
  const { t } = useTranslation();

  const getDisplayName = () => {
    if (friend.displayName) return friend.displayName;
    if (friend.username) return friend.username;
    return t('common.anonymous');
  };

  return (
    <button
      type="button"
      className={`${styles.friendItem} ${isSelected ? styles.selected : ''} ${disabled ? styles.disabled : ''}`}
      onClick={() => onToggleSelect(friend.id)}
      disabled={disabled}
    >
      <div className={styles.friendInfo}>
        <div className={styles.avatarContainer}>
          {friend.profilePictureUrl ? (
            <img
              src={friend.profilePictureUrl}
              alt={t('friends.profile_picture_alt', { name: getDisplayName() })}
              className={styles.avatar}
              loading="lazy"
            />
          ) : (
            <DefaultAvatar
              name={getDisplayName()}
              size={48}
              fallbackColor="var(--color-primary-light)"
              textColor="var(--color-surface)"
            />
          )}
        </div>
        <span className={styles.name}>{getDisplayName()}</span>
      </div>
      <div className={`${styles.checkbox} ${isSelected ? styles.checked : ''}`}>
        <Check size={14} className={styles.checkIcon} />
      </div>
    </button>
  );
};

export default SelectableFriendItem;
