// src/components/PostList.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PostItem from './PostItem';
import { useHits, useSearchBox, useInstantSearch } from 'react-instantsearch';
import { usePost } from '../hooks/usePost';

const PostList = ({ defaultPosts, isCurrentUser, activeTab, onPostDeleted }) => {
  const { t } = useTranslation();
  const { hits } = useHits();
  const { query } = useSearchBox();
  const { posts } = usePost();
  const { refresh } = useInstantSearch();

  // State to keep track of deleted post IDs
  const [deletedPostIds, setDeletedPostIds] = useState([]);

  // Define a local handlePostDelete function
  const handlePostDelete = (deletedPostId) => {
    onPostDeleted(deletedPostId); // Call the parent function
    setDeletedPostIds((prevIds) => [...prevIds, deletedPostId]); // Add deleted post ID to state
    refresh(); // Refresh the search results
  };

  // Filter out deleted posts from displayPosts
  const displayPosts = query 
    ? hits.filter((post) => !deletedPostIds.includes(post.objectID || post.id))
    : (activeTab === 'posts' ? (posts.length > 0 ? posts : defaultPosts) : defaultPosts);

  return (
    <div>
      <h3>{t('posts.title')}</h3>
      {displayPosts.length === 0 ? (
        <p>{t('posts.no_posts')}</p>
      ) : (
        displayPosts.map(post => (
          <PostItem
            key={post.objectID || post.id}
            post={post}
            isCurrentUser={isCurrentUser}
            onPostDeleted={handlePostDelete} // Use the local function
          />
        ))
      )}
    </div>
  );
};

export default PostList;