// src/components/PostDetailView.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getPost } from '../services/postService';
import PostItem from './PostItem';

const PostDetailView = () => {
  const { t } = useTranslation();
  const { postId } = useParams();
  const location = useLocation();
  const commentIndex = location.state && location.state.commentIndex != null ? Number(location.state.commentIndex) : null;
  const replyIndex = location.state && location.state.replyIndex != null ? Number(location.state.replyIndex) : null;
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      setError(false);
      try {
        const fetchedPost = await getPost(postId);
        setPost(fetchedPost);
      } catch (err) {
        console.error('Error fetching post:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [postId]);

  const handlePostUpdate = (updatedPost) => {
    setPost(updatedPost);
  };

  const handlePostDelete = () => {
    navigate('/'); // Redirect to home page after post is deleted
  };

  if (loading) return <div>{t('loading')}</div>;
  if (error) return <div>{t('posts.fetch_error')}</div>;
  if (!post) return <div>{t('posts.not_found')}</div>;

  return (
    <div className="post-detail-view" key={postId}>
      <PostItem 
        post={post} 
        onPostUpdated={handlePostUpdate} 
        onPostDeleted={handlePostDelete}
        isDetailView={true}
        highlightedCommentIndex={commentIndex}
        highlightedReplyIndex={replyIndex}
      />
    </div>
  );
};

export default PostDetailView;