// src/components/GroupSettings.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  X, 
  Camera, 
  Users, 
  UserPlus,
  LogOut,
  Trash2,
  Crown,
  ChevronRight,
  Bell,
  BellOff
} from 'lucide-react';
import { auth, db, storage } from '../firebase';
import { 
  doc,
  updateDoc,
  collection,
  getDoc,
  getDocs,
  writeBatch,
  arrayRemove,
  serverTimestamp
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import AddMembersModal from './AddMembersModal';
import styles from './GroupSettings.module.css';

const GroupSettings = ({ group, onClose, onUpdate, onLeaveGroup, notificationsEnabled, onToggleNotifications, isAdmin = false }) => {
  const { t } = useTranslation();
  const [name, setName] = useState(group.name);
  const [iconFile, setIconFile] = useState(null);
  const [iconPreview, setIconPreview] = useState(group.icon);
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAddMembers, setShowAddMembers] = useState(false);
  const [showMembersList, setShowMembersList] = useState(false);

  // Load members and check admin status
  useEffect(() => {
    const loadMembersAndCheckAdmin = async () => {
      try {
        // Get all members
        const membersRef = collection(db, `groups/${group.id}/members`);
        const membersSnap = await getDocs(membersRef);
  
        const memberPromises = membersSnap.docs.map(async (memberDoc) => {
          const memberData = memberDoc.data();
          const userId = memberDoc.id; // Use memberDoc.id as the userId
  
          // Get user data for each member
          const userRef = doc(db, 'users', userId);
          const userSnap = await getDoc(userRef);
          const userData = userSnap.exists() ? userSnap.data() : null;
  
          return {
            id: memberDoc.id, // This will be the userId
            userId: userId,
            role: memberData.role,
            joinedAt: memberData.joinedAt,
            displayName: userData?.displayName || t('common.anonymous'),
            profilePictureUrl: userData?.profilePictureUrl,
          };
        });
  
        // Await all promises
        const resolvedMembers = await Promise.all(memberPromises);
        setMembers(resolvedMembers);
      } catch (error) {
        console.error('Error loading members:', error);
        setError(t('groups.load_members_error'));
      }
    };
  
    loadMembersAndCheckAdmin();
  }, [group.id, t]);

  useEffect(() => {
    if (iconFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setIconPreview(e.target.result);
      };
      reader.readAsDataURL(iconFile);
    }
  }, [iconFile]);

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setIconFile(file);
    }
  };

  const handleSave = async () => {
    if (!name.trim()) {
      setError(t('groups.name_required'));
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      let newIconUrl = group.icon;
      
      if (iconFile) {
        const iconRef = ref(storage, `group-icons/${group.id}/${Date.now()}-${iconFile.name}`);
        await uploadBytes(iconRef, iconFile);
        newIconUrl = await getDownloadURL(iconRef);
      }

      await updateDoc(doc(db, 'groups', group.id), {
        name: name.trim(),
        icon: newIconUrl,
        updatedAt: serverTimestamp(),
        lastActivityAt: serverTimestamp()
      });

      onUpdate({
        ...group,
        name: name.trim(),
        icon: newIconUrl,
        lastActivityAt: new Date()
      });
      
      onClose();
    } catch (error) {
      console.error('Error updating group:', error);
      setError(t('groups.update_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveMember = async (memberId, memberUserId) => {
    if (!isAdmin || memberUserId === auth.currentUser.uid) return;

    try {
      const batch = writeBatch(db);
      
      // Delete member document
      batch.delete(doc(db, `groups/${group.id}/members/${memberId}`));
      
      // Update group member count and remove member from members array
      const groupRef = doc(db, 'groups', group.id);
      batch.update(groupRef, {
        memberCount: group.memberCount - 1,
        members: arrayRemove(memberUserId), // Remove the member's UID from the members array
        lastActivityAt: serverTimestamp()
      });

      await batch.commit();

      // Update local state
      setMembers(prevMembers => prevMembers.filter(m => m.id !== memberId));

      // Update the group's memberCount and members array in the parent component
      onUpdate({
        ...group,
        memberCount: group.memberCount - 1,
        members: group.members.filter(uid => uid !== memberUserId),
        lastActivityAt: new Date()
      });
    } catch (error) {
      console.error('Error removing member:', error);
      setError(t('groups.remove_member_error'));
    }
  };

  const handleMembersAdded = (newMembers) => {
    setMembers(prevMembers => [...prevMembers, ...newMembers]);
    // Update the group's member count and members array in the parent component
    onUpdate({
      ...group,
      memberCount: group.memberCount + newMembers.length,
      members: [...group.members, ...newMembers.map(member => member.userId)],
      lastActivityAt: new Date()
    });
  };

  const handleLeaveGroup = async () => {
    if (isAdmin && members.filter(m => m.role === 'admin').length === 1) {
      setError(t('groups.last_admin_error'));
      return;
    }

    try {
      const currentUserMember = members.find(m => m.userId === auth.currentUser.uid);
      if (!currentUserMember) return;

      const batch = writeBatch(db);
      
      // Delete member document
      batch.delete(doc(db, `groups/${group.id}/members/${currentUserMember.id}`));
      
      // Update group member count and remove member from members array
      const groupRef = doc(db, 'groups', group.id);
      batch.update(groupRef, {
        memberCount: group.memberCount - 1,
        members: arrayRemove(auth.currentUser.uid),
        lastActivityAt: serverTimestamp()
      });

      await batch.commit();

      // Optionally, you can update the local state or parent component
      onLeaveGroup();
    } catch (error) {
      console.error('Error leaving group:', error);
      setError(t('groups.leave_error'));
    }
  };

  const renderMembersList = () => (
    <div className={styles.membersView}>
      <div className={styles.membersViewHeader}>
        <button onClick={() => setShowMembersList(false)} className={styles.backButton}>
          <ChevronRight size={24} />
        </button>
        <h2>{t('groups.members')}</h2>
        <button 
          className={styles.addMemberButton}
          onClick={() => setShowAddMembers(true)}
        >
          <UserPlus size={20} />
        </button>
      </div>
      <div className={styles.membersList}>
        {members.map((member) => (
          <div key={member.id} className={styles.memberItem}>
            <div className={styles.memberInfo}>
              {member.profilePictureUrl ? (
                <img
                  src={member.profilePictureUrl}
                  alt={member.displayName}
                  className={styles.memberAvatar}
                />
              ) : (
                <div className={styles.memberAvatarPlaceholder}>
                  {member.displayName[0].toUpperCase()}
                </div>
              )}
              <span className={styles.memberName}>
                {member.displayName}
                {member.role === 'admin' && (
                  <Crown size={14} className={styles.adminBadge} />
                )}
              </span>
            </div>
            {isAdmin && member.userId !== auth.currentUser.uid && (
              <button
                onClick={() => handleRemoveMember(member.id, member.userId)}
                className={styles.removeMemberButton}
                aria-label={t('groups.remove_member')}
              >
                <Trash2 size={16} />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );

  const renderMainView = () => (
    <>
      <div className={styles.settingsHeader}>
        <button onClick={onClose} className={styles.closeButton}>
          <X size={24} />
        </button>
        <h2>{t('groups.settings')}</h2>
        <button onClick={handleSave} className={styles.saveButton} disabled={isLoading}>
          {isLoading ? t('common.saving') : t('common.save')}
        </button>
      </div>

      <div className={styles.settingsContent}>
        <div className={styles.groupInfo}>
          <div className={styles.groupIconUpload}>
            <input
              type="file"
              id="group-icon"
              accept="image/*"
              onChange={handleIconChange}
              className={styles.hidden}
            />
            <label 
              htmlFor="group-icon" 
              className={`${styles.iconLabel} ${!isAdmin ? styles.disabled : ''}`}
            >
              {iconPreview ? (
                <img
                  src={iconPreview}
                  alt={t('groups.group_icon')}
                  className={styles.iconPreview}
                />
              ) : (
                <div className={styles.iconPlaceholder}>
                  <Users size={32} />
                </div>
              )}
              <div className={styles.cameraBadge}>
                <Camera size={16} />
              </div>
            </label>
          </div>
          <input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder={t('groups.group_name')}
            className={styles.groupNameInput}
          />
        </div>

        <div className={styles.settingsMenu}>
          <button 
            className={styles.menuItem}
            onClick={() => setShowMembersList(true)}
          >
            <div className={styles.menuItemContent}>
              <Users size={24} />
              <span>{t('groups.members')}</span>
            </div>
            <div className={styles.menuItemRight}>
              <span className={styles.memberCount}>{members.length}</span>
              <ChevronRight size={20} />
            </div>
          </button>

          <button 
            className={styles.menuItem}
            onClick={onToggleNotifications}
          >
            <div className={styles.menuItemContent}>
              {notificationsEnabled ? (
                <Bell size={24} />
              ) : (
                <BellOff size={24} />
              )}
              <span>{t('groups.notification_settings')}</span>
            </div>
            <div className={styles.toggleSwitch}>
              <input
                type="checkbox"
                checked={notificationsEnabled}
                onChange={onToggleNotifications}
                className={styles.toggleInput}
              />
              <span className={styles.toggleSlider}></span>
            </div>
          </button>

          <button
            onClick={handleLeaveGroup}
            className={`${styles.menuItem} ${styles.danger}`}
          >
            <div className={styles.menuItemContent}>
              <LogOut size={24} />
              <span>{t('groups.leave_group')}</span>
            </div>
          </button>
        </div>

        {error && <div className={styles.errorMessage}>{error}</div>}
      </div>
    </>
  );

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.settingsModal} onClick={e => e.stopPropagation()}>
        {showMembersList ? renderMembersList() : renderMainView()}
      </div>

      {showAddMembers && (
        <AddMembersModal
          group={group}
          existingMembers={members}
          onClose={() => setShowAddMembers(false)}
          onMembersAdded={handleMembersAdded}
        />
      )}
    </div>
  );
};

export default GroupSettings;