// src/hooks/useUserProfile.js
import { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import i18n from 'i18next';

const useUserProfile = (userId) => {
  const [profile, setProfile] = useState(null);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [profileError, setProfileError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      setLoadingProfile(true);
      const currentUser = auth.currentUser;

      if (!currentUser) {
        setProfileError(i18n.t('errors.user_not_authenticated'));
        setLoadingProfile(false);
        return;
      }

      setIsCurrentUser(userId === currentUser.uid);

      try {
        const docRef = doc(db, 'users', userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProfile(docSnap.data());
        } else {
          setProfileError(i18n.t('profile.not_found'));
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        setProfileError(i18n.t('profile.fetch_error'));
      } finally {
        setLoadingProfile(false);
      }
    };

    fetchProfile();
  }, [userId]);

  return { profile, isCurrentUser, loadingProfile, profileError, setProfile };
};

export default useUserProfile;